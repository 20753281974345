
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Typography,
  notification,
  Input,
  Button, 
  Form
} from "antd";
import {  PlusCircleOutlined  } from "@ant-design/icons";
import {  postFarmer, getAllAgriculturistApi } from "../../services/supplierApi";
// import { DeleteOutlined} from '@ant-design/icons';


const { Text } = Typography;


function MyAgriculturistList() {
  const [loading, setLoading] = useState(false);
  const [agriculturistList, setAgriculturistList] = useState([])
  // // const [invoices, setInvoices] = useState<any>();
  // const [psize, setPsize] = useState<any>(10);
  // const [page, setpage] = useState<any>(1);
  // const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [farmerAdd] = Form.useForm();

  const fetchAllAgriculturist  = async () => {
    try {
      setLoading(true);
      const response: any = await getAllAgriculturistApi(); 
      if (response) {
        setLoading(false);
        setAgriculturistList(response?.data?.agriculturist)
      }
    } catch (error: any) {
      setLoading(false);   
    }
  }

  // const handleShowSize = (current: any, size: any) => {
  //   setpage(current);
  //   setPsize(size);
  // };



  // const deletConfirm = async (value: any) => {
  //   try {
  //     setLoading(true);
  //     const response: any = await deleteSupplierById(value);
  //     if (response) {
  //       setLoading(false);
  //       fetchAllSuppliers(page,psize);
  //     }
  //   }
  //   catch(e){
  //     console.log(e)
  //   }
  //    finally {
  //     setLoading(false);
  //   }
  // };

  const columns :any = [
    {
      title: 'İsim',
      dataIndex: 'name',
      key: 'name',
      width:220,
      fixed: 'left'
    },
    {
      title: 'Soyad',
      dataIndex: 'surname',
      key: 'surname',
      width:220,
    },
    {
      title: 'Telefon numarası',
      dataIndex: 'gsmNumber',
      key: 'gsmNumber',
      width:220,
    },
    {
      title: 'Vergi Kimlik No',
      dataIndex: 'taxId',
      key: 'taxId',    
      width:220,  
    },
    // {
    //   title: '',
    //   dataIndex: 'agroId',
    //   key: 'agroId',
    //   align :'center',
    //   width: 100,
    //   render: (value: string, record: any) => (
    //     <>
    //    {(record && !record.isRegistered) ? (
    //       <Popconfirm
    //         title="Çiftçiyi silmek istediğinizden emin misiniz?"
    //         onConfirm={() => deletConfirm(value)}
    //         overlayClassName="delete-pop"
    //         okText="Evet Sil"
    //         cancelText="Vazgeç"
    //       >
    //         <DeleteOutlined style={{fontSize: 18, color: 'red'}}/>
    //       </Popconfirm>
    //       ) : ''}
    //     </>
    //   ),
    // },
  ]

  const addFarmer = async (values:any) => {     
    try {     
      setLoading(true);
      const response: any = await postFarmer({
        agroTaxId : values.agroTaxId,
        name : values.name,
        surname : values.surname,
        gsmNumber : values.gsmNumber,
             
      });     
        if (response) {
          setLoading(false);
          fetchAllAgriculturist();
          farmerAdd.resetFields();
          notification.success({
            message: `Başarıyla kayıt oluşturuldu.`,
          });
        }
    } catch (error: any) {
      setLoading(false);
      console.log(error)
      notification.warning({
        message: "Vergi Kimlik yada telefon numarası bilgisi daha önce kullanılmış.",
        duration: 3,
      });
    }
    
   };
  
   useEffect(() => {
    fetchAllAgriculturist();
  }, []);

  const columnsInput: any = [
    {
      key: 'name',
      title: 'İsim',
      dataIndex: 'name',
      width:200,
      render: () =>  <Form.Item name="name"  
        rules={[{
          required: true, 
          message: "Ad girmelisiniz."
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      key: 'surname',
      title: 'Soyisim',
      dataIndex: 'surname',
      width:200,
      render: () =>  <Form.Item name="surname"  
        rules={[{
          required: true, 
          message: "Soyad girmelisiniz."
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: 'Telefon numarası',
      dataIndex: 'gsmNumber',
      width:200,
      render: () => <Form.Item name="gsmNumber"  
        rules={[{
          required: true, 
          validator: (_, value) => {
            const patt = new RegExp('(5)[0-9][0-9][0-9]([0-9]){6}');
            return patt.test(value) && value.length < 11
                ? Promise.resolve()
                : Promise.reject(
                      new Error(
                          'Lütfen telefon numaranızı 5xxxxxxxxx şeklinde 10 basamak olarak giriniz!'
                      )
                  );
        },
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: 'Vergi Kimlik No',
      dataIndex: 'agroTaxId',
      width:200,
      render: () => <Form.Item name="agroTaxId"  
        rules={[{
          required: true, 
          validator: (_, value) => {
          return  value?.toString().length === 10 || value?.toString().length === 11
            ? Promise.resolve()
            : Promise.reject(new Error('Lütfen geçerli bir VKN / TCKN giriniz!'));
        },
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: '',
      dataIndex: 'action',
      width: '50px',
      height :'30px',
      render: () => <Button htmlType="submit" loading={loading}
      style={{border:'none', fontSize:'24px', alignItems:'center', display:'flex', height:'30px', background:'none'}}>
        <PlusCircleOutlined/></Button>
    }
  ];


  return (
    <Row>
    <Col span={24} style={{ marginBottom: 20 }}>
      <Text className="limit-title" >Çiftçilerim</Text>
    </Col>
    <Col span={24}>
      <p>
      Platforma yüklediğiniz Çiftçilerinizin listesini burada görebilirsiniz. Henüz kayıt olmamış olan Çiftçilerinizi silebilirsiniz.
      </p>
    </Col>
      <Col span={24} style={{ margin: "0px auto" }}>

      <Form name="farmerAdd" form={farmerAdd} onFinish={addFarmer}>
          <Table 
            className="supplier-list list-new-th"
            columns={columnsInput}
            dataSource={[{}]}
            pagination={false}
            scroll={{
              x: 900,
            }}
          />
      </Form>
        
        <Table
          className="w-100 invoiceTable"
          showHeader={false}
          pagination={{
            position: ["bottomLeft", "none" as any],
            showLessItems: true,
            showSizeChanger: true,
            // current: page,
            // pageSize:psize,
            // total:totalDataCount, 
            // onChange: handleShowSize,
          }}
          loading={loading}
          dataSource={agriculturistList}
          columns={columns}
          rowKey="supplierId"
          scroll={{
            x: 900,
          }}
        />
      </Col>
    </Row>
  );
}

export default MyAgriculturistList;

