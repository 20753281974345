import React, { useEffect, useState } from "react";
import InvoicePDF from "../assets/pdf_icon.png";
import InvoiceJPEG from "../assets/jpeg_icon.png";
import InvoicePNG from "../assets/invoice.png";
import "./PreviewComponents/styles.css";
import { DeleteOutlined } from "@ant-design/icons";
import { Image } from "antd";
import Viewer from './PreviewComponents/Viewer';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Preview({
  imageName= "",
  src = "",
  height = "50px",
  width = "60px",
  onDelete = undefined,
  isFilePdf = undefined,
}) {
  const [isPdf, setIsPdf] = useState<any>(src && src.endsWith("pdf"));
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isFilePdf === undefined) {
      setIsPdf(src.endsWith("pdf"));
    } else {
      setIsPdf(isFilePdf);
    }
  }, [src, isFilePdf, setIsPdf]);

  const openPdfNewTab = () => {
    window.open(src);
  };

  return (
    <>
      <div className="previewContainer">
        {!!onDelete && <DeleteOutlined className="deleteIcon" />}
        {!isPdf ? (
          <Image
            className="faturaImg"
            src={src.endsWith("png") ? InvoicePNG : InvoiceJPEG}
            height={height}
            width={width}
            onClick={() => setIsModalVisible(true)}
            preview={false}
          />
        ) : (
          <div
            className="PdfContainer"
            role="button"
            tabIndex={0}
            style={{ height: height, width: width }}
            onClick={openPdfNewTab}
          >
            <Image
              className="faturaImg"
              height={height}
              width={width}
              style={{ width: "35px", height: "40px", marginLeft: '6px' }}
              alt="invoiceimg"
              src={InvoicePDF}
              preview={false}
            />
          </div>
        )}
      </div>

      {!isPdf && (
        <Viewer
          imageName={imageName}
          images={[{ src }]}
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
}

export default Preview;
