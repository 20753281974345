export function downloadImage(image: any, imageName: any) {
    const degree = image.degree || 0;
  
    const canvas: any = document.createElement('canvas');
    let downloadedImg: any = null;
    canvas.style.visibility = 'hidden';
  
    function startDownload() {
      downloadedImg = new Image();
      downloadedImg.crossOrigin = 'Anonymous';
      downloadedImg.addEventListener('load', imageReceived, false);
      downloadedImg.src = image.src;
    }
  
    const rotateImage = () => {
      const ctx: any = canvas.getContext('2d');
  
      if (degree % 180) {
        canvas.width = downloadedImg.height;
        canvas.height = downloadedImg.width;
      } else {
        canvas.width = downloadedImg.width;
        canvas.height = downloadedImg.height;
      }
  
      ctx.translate(canvas.width / 2, canvas.height / 2);
  
      ctx.rotate((degree * Math.PI) / 180);
      ctx.drawImage(downloadedImg, -downloadedImg.width / 2, -downloadedImg.height / 2);
    };
  
    function imageReceived() {
      rotateImage();
  
      document.body.appendChild(canvas);
      try {
        const downloadButton: any = document.createElement('a');
        downloadButton.href = canvas.toDataURL('image/jpg');
        downloadButton.download = imageName;
        document.body.appendChild(downloadButton);
        downloadButton.click();
        downloadButton.parentNode.removeChild(downloadButton);
        canvas.parentNode.removeChild(canvas);
      } catch (err) {
        // console.log(`Error: ${err}`);
      }
    }
  
    startDownload();
  }
  