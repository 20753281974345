import React, { useState } from "react";
import { Button, Col, Form, Input, Layout, notification, Row } from 'antd';
import { useNavigate } from "react-router-dom";
import { forgetPasswordApi } from '../services/userAuth';
import logo from "../assets/logo.png";

const Content = Layout.Content;

const ForgetPassword = () => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const onForgetPassword = async (args: any) => {
        try {
            setIsLoading(true);
            const response: any = await forgetPasswordApi(args);
            if (response?.status === 200) {
                setIsLoading(false);
                notification.success({
                    message: "Lütfen mailinizi kontrol ediniz",
                    duration: 3,
                })
            }
        } catch (error: any) {
            setIsLoading(false);
            if(error.response.data.Status === 404 && error.response.data.Type.includes('BuyerUserNotFoundException') ){
                notification.warning({
                    message: `Kullanıcı email adresi hatalıdır.`,
                });
            }else{
                notification.error({
                    message: 'Bilinmedik bir hata oluştu.',
                    duration: 3,
                })
            }
        }
    }
    return (
        <Layout className="layout">
            <Row className="header-login"> 
                <div>
                    <img height="40px" className="mb-big" src={logo} alt="Depar.io" />
                </div>
            </Row>
            <Content className="content">
                <Row justify="center">
                    <Col xs={24} md={12} lg={7}>
                        <p className="loginSpan">Şifre Yenileme</p>
                        <Form name="login" onFinish={onForgetPassword} >
                            <Form.Item
                                className="email-input"
                                style={{ margin: "0px" }}
                                name="email"
                                rules={[
                                    { type: 'email', message: 'Lütfen geçerli bir e-posta adresi giriniz!' },
                                    {
                                        required: true,
                                        message: 'Lütfen email giriniz!',
                                    },
                                ]}
                            >
                                <Input placeholder="E-Posta Adresi" />
                            </Form.Item>

                            <Form.Item style={{marginTop: 30}}>
                                <Button
                                    className="login-btn"
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={isLoading}
                                    block
                                >
                                    Gönder
                                </Button>
                            </Form.Item>
                        
                            <div className="forgotPassword" onClick={()=> navigate("/")}
                            style={{cursor:'pointer'}} >
                                Geri Dön
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default ForgetPassword;