import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { LocalStorageService } from '../helpers/LocalStorage';
import {v1ApiUrls} from './config';
import {urls} from '../router/auth';



export class ServiceHelper {
  static baseUrl: any = v1ApiUrls;
  
  static setDefaultConfiguration(config?: AxiosRequestConfig) {

    let token = LocalStorageService.getAuthToken();

    interface AxiosConfig extends AxiosRequestConfig {
      retryCount?: number;
    }
    interface AxiosConfigCustom extends AxiosConfig {
      retryCount: number;
    }
    interface AxiosErrorCustom extends AxiosError {
      config: AxiosConfigCustom;
    }

    if (token) {
      if (config?.headers) {       
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      else if (config)
        config.headers = {  Authorization:`Bearer ${token}` }
      else
        config = { headers: {Authorization:`Bearer ${token}` } }
    }

  
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosErrorCustom) => {
        // 401 hatası kontrolü
        if (error.response?.status === 401) {
          LocalStorageService.removeAuthToken();
          LocalStorageService.removeAuthName();
          LocalStorageService.removeBuyerType();
          if (window.location.pathname !== urls.login) {
            window.location.href = urls.login;
          }
          return Promise.reject(error);
        } 
        
        // 500 hatası ve "There is no token for this user" kontrolü
        else if (error.response?.status === 500 && error.response?.data.Title === "There is no token for this user") {
          LocalStorageService.removeAuthToken();
          LocalStorageService.removeAuthName();
          LocalStorageService.removeBuyerType();
          window.location.reload();
          // if (window.location.pathname !== urls.login) {
          //   window.location.href = urls.login;
          // }
          return Promise.reject(error);
        } 
        
        else {
          return Promise.reject(error);
        }
      }
    );
  }



  static async get(url: string, config?: AxiosRequestConfig) {
    ServiceHelper.setDefaultConfiguration(config);
    const response: any = await axios.get(ServiceHelper.baseUrl + url, config);
    return response;
  }
  static async post(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig, noToken?: boolean) {
    if (!noToken)
      ServiceHelper.setDefaultConfiguration(config);
    const response: any = await axios.post(ServiceHelper.baseUrl + url, payload, config);
    return response;
  }

  static async postt(url: string, config?: AxiosRequestConfig, noToken?: boolean) {
    if (!noToken)
      ServiceHelper.setDefaultConfiguration(config);
    const response: any = await axios.post(ServiceHelper.baseUrl + url, null, config);
    return response;
  }

  static async put(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig) {
    ServiceHelper.setDefaultConfiguration(config);
    const response: any = await axios.put(ServiceHelper.baseUrl + url, payload, config);
    return response;
  }

  static async patch(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig) {
    ServiceHelper.setDefaultConfiguration(config);
    const response: any = await axios.patch(ServiceHelper.baseUrl + url, payload, config);
    return response;
  }

  static async delete(url: string, config?: AxiosRequestConfig) {
    ServiceHelper.setDefaultConfiguration(config);
    const response: any = await axios.delete(ServiceHelper.baseUrl + url, config);
    return response;
  }

  static async deleteWithBody(url: string,payload?: Record<string, any> ,config?: AxiosRequestConfig) {
    ServiceHelper.setDefaultConfiguration(config);
    const response: any = await   axios.delete(ServiceHelper.baseUrl + url, {
      headers: config?.headers ,
      data: payload 
    });
    return response;
  }
}
