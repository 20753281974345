
// NUMBERS
export const decimalSeperator = ','; // 0,12
export const integerSeperator = '.'; // 100.000


export const convertNumberDotSeperated = (number: number) => {
    if (!number) return '0';
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, integerSeperator);
};

export const convertFloatDotSeperated = (number: any) => {
    if (!number) return '0';
    const floatString = parseFloat(number).toFixed(2).replace('.', decimalSeperator);
    return floatString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, integerSeperator);
};
export const convertCurrencyTl = (number: number) => `${convertFloatDotSeperated(number)} TL`;

export const convertDotSeperatedToNumberString = (dotSeperated = '') => {
    const [integer = '', decimal = ''] = dotSeperated.split(decimalSeperator);
    const number = integer.split('.').join('');

    if (decimal) return `${number}.${decimal}`;

    return number;
};
export const convertDotSeperatedToFloat = (dotSeperated = '') =>
    parseFloat(convertDotSeperatedToNumberString(dotSeperated));

export const downloadFile = (fileContent: any) => {
    const byteStr = atob(fileContent);
    const arrayBuffer = new ArrayBuffer(byteStr.length);
    const int8Array: any = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteStr.length; i++) {
        int8Array[i] = byteStr.charCodeAt(i);
    }
    const fileUrl = URL.createObjectURL(
        new Blob([int8Array], {
        type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        })
    );
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "Fatura");
    document.body.appendChild(link);
    link.click();
}

export const mapArray = (array: any, key: any) =>
    array.reduce(
        (prevs: any, curObject: any) => ({
            ...prevs,
            [curObject[key]]: curObject,
        }),
        {}
    );

export const chequeStatuses = [
    {
        id: 1,
        value: 'YUKLENDI',
        color: '#0b3163',
        text: 'Yüklendi',
        desc: 'Tedarikçi faturanız başarılı bir şekilde platforma yüklendi.',
    },
    { id: 2, 
        value: 'TEMLIK_EDILDI', 
        color: '#0ee373', 
        text: 'Temlik Edildi',
        desc : 'Tedarikçiniz faturayı finans kurumuna temlik etti.'
     },
    {
        id: 3,
        value: 'ISKONTO_T_BULUNULDU',
        color: '#b7b700',
        text: 'İskonto Talebinde Bulunuldu',
        desc: 'Tedarikçiniz fatura için iskonto talebinde bulundu.',
    },
    {
        id: 4,
        value: 'VADESI_GECTI',
        color: '#faad14',
        text: 'Vadesi Geçti',
        desc: 'Tedarikçinizin faturasının süresi doldu.',
    },
    {
        id: 5,
        value: 'REDDEDILDI',
        color: '#ff0000',
        text: 'Reddedildi',
        desc: 'Fatura iskonto talebi finans kurumu tarafından reddedildi.',
    },
    {
        id: 6,
        value: 'ON_ODEME_YAPILDI',
        color: '#08d92c',
        text: 'Ön Ödeme Alındı',
        desc: 'Finans kurumu fatura ön ödemesini gerçekleştirdi.',
    },
    { id: 7, 
        value: 'ODENDI', 
        color: '#40a9ff', 
        text: 'Ödendi',
        desc : 'Finans kurumuna fatura ödemesini gerçekleştirdiniz.'
     },
   
];
export const chequeStatusMapById = mapArray(chequeStatuses, 'id');
export const chequeStatusMapByValue = mapArray(chequeStatuses, 'value');
