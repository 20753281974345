import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Input,
  notification,
  Layout,
  Button,
  Checkbox, Modal, Tabs
} from "antd";
import moment from "moment";
import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import {  sendTermsEmailApi, resetPasswordApi } from "../services/userAuth";
import { Content } from "antd/lib/layout/layout";
import logo from "../assets/logo.png";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import MembershipAgreement from './Documents/MembershipAgreement';
import ClarificationText from './Documents/ClarificationText';
import ExplicitConsentText from './Documents/ExplicitConsentText';
import {validatePassword} from '../utils/validators';
import illuminationText from '../assets/aydinlatma-metni.pdf';
import expressConsent from '../assets/kvkk-acik-riza-beyani.pdf';
import NotoFont from '../assets/NotoSerif-Regular.ttf'


const CreatePassword: React.VFC = () => {
  let navigate = useNavigate();
  const [isLoding, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabShow, setTabShow] = useState(1); 
  const [register, setRegister] = useState(true);
  const [illuminationTextData, setIlluminationTextData] = useState('');
  const [expressConsentData, setExpressConsentData] = useState('');
  const [name, setName] = useState('');

  const { TabPane } = Tabs;
  const location = useLocation();
  const token = location.pathname.split('/');
  const buyerUserId = token[3];
  const passwordToken = token[2] === 'create'  && token[4];
 

const onChangeCheck = () => {
    setRegister(!register)
  };

const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onChangeTab = (key:any) => {
    setTabShow(key)
  };

const showModal = (keyX:any) => {
    setIsModalOpen(true);
     setTabShow(keyX)
  };

const toBase64 = (u8:any) => btoa(u8.reduce((data:any, byte:any) => data + String.fromCharCode(byte), ''));


const sendTermsEmail = async () => {
  
  const terms : any = [];
  terms.push(
    {
    base64File: illuminationTextData,
    fileName: 'Neo Elektronik - Kişisel Verilere İlişkin Aydınlatma Metni.pdf'
    },
    {
      base64File: expressConsentData,
      fileName: 'Neo Elektronik - KVKK ile İlgili Açık Rıza Beyanı.pdf'
    }
  )
  try{
    const res:any = await sendTermsEmailApi(location.pathname.split('/')[3], {terms})
    if(res?.status === 204){
      setIsLoading(false);
       notification.success({
        message: 'Başarıyla şifre oluşturdunuz!',
        description: 'Yeni şifrenizi kullanarak giriş yapabilirsiniz.',
      });
      setTimeout(() => {  
        navigate("/login");
      },1000)
    }
   
  }catch(e:any){
    console.log('hata',e)
  } 
}

const sendEmail = async () => {
  const date = new Date();
 
  const fontBytes = await fetch(NotoFont).then((res) => res.arrayBuffer());
  const illuminationTextPdfBytes = await fetch(illuminationText)
  .then((res) => res.arrayBuffer())
  .then((arrayBufferData) => arrayBufferData);
  if (illuminationTextPdfBytes) {
      const pdfDocIlluminationText = await PDFDocument.load(illuminationTextPdfBytes, {
          updateMetadata: false,
      });

      pdfDocIlluminationText.registerFontkit(fontkit);
      const customFont = await pdfDocIlluminationText.embedFont(fontBytes)
     
      const pagesOne = pdfDocIlluminationText.getPages();
      pagesOne[3].moveTo(40, 320);
      pagesOne[3].drawText(moment(date.toDateString()).format('DD/MM/YYYY') , {
            size: 10,
      });
      pagesOne[3].moveTo(40, 306);
      pagesOne[3].drawText( name , {
            size: 10,
            font: customFont,
      });
      
      const illuminationTextPdf = await pdfDocIlluminationText.save();
      setIlluminationTextData(toBase64(illuminationTextPdf))
  }

  const expressConsentPdfBytes = await fetch(expressConsent)
  .then((res) => res.arrayBuffer())
  .then((arrayBufferData) => arrayBufferData);
    if (expressConsentPdfBytes) {
      const pdfDocExpressConsent = await PDFDocument.load(expressConsentPdfBytes, {
          updateMetadata: false,
      });

      pdfDocExpressConsent.registerFontkit(fontkit);
      const customFont = await pdfDocExpressConsent.embedFont(fontBytes)
  
      const pagesTwo = pdfDocExpressConsent.getPages();
      pagesTwo[0].moveTo(100, 202);
      pagesTwo[0].drawText(moment(date.toDateString()).format('DD/MM/YYYY') , {
          size: 10,
      });
      pagesTwo[0].moveTo(120, 180);
      pagesTwo[0].drawText( name , {
          size: 10,
          font: customFont,
      });

      const expressConsentPdf = await pdfDocExpressConsent.save();
      setExpressConsentData(toBase64(expressConsentPdf));
    }
}

  const onResetPassword = async (args: any) => {
    try {
       setIsLoading(true);
      if(args.password && args.confirmPassword && args.password === args.confirmPassword ) {
        let data: any = {
          passwordToken: passwordToken || '',
          newPassword: args.password,
        }
        const response: any = await resetPasswordApi(data, buyerUserId );
        if (response?.status === 200) {
          setName(response?.data?.username);
        }
      } else {
        setIsLoading(false);
        notification.error({
          message: 'Şifre ve şifreyi onayla eşleşmiyor',
          duration: 3,
        });
      }
    } catch (error: any) {
      setIsLoading(false);
      if(error.response.data.Status === 400 && error.response.data.Messages ){
        const length = error.response.data.Messages.length - 1;
        let x = 0;
        for( x=0; x < length + 1 ; x+=1){
            notification.warning({
                message: ` ${error.response.data.Messages[x]}`  
            });
        } 
      }
      error.response.data.title && notification.error({
        message: 'Beklenmedik bir hata oluştu.',
        duration: 3,
      });
    }
  };

  useEffect(() => {
    if(name !== ''){
      sendEmail();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
   },[name])
 

  useEffect(() => {
    if(illuminationTextData !== '' && expressConsentData !== ''){
     sendTermsEmail();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
   },[illuminationTextData,expressConsentData])


  return (
    <Layout className="layout">
      <Row className="header-login"> 
          <div>
              <img height="40px" className="mb-big" src={logo} alt="Depar.io" />
          </div>
      </Row>
      <Content className="content">
        <Row justify="center">
          <Col xs={24} md={16} lg={8}>
            <span className="loginSpan">Şifre Oluştur</span>
            <Form name="login" onFinish={onResetPassword}>
              <Form.Item
                style={{ margin: "0px" }}
                name="password"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePassword(value)
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Şifre"
                  iconRender={(visible: any) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item
                style={{ margin: "0px" }}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePassword(value)
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Şifreyi Onayla"
                  iconRender={(visible: any) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              <div className='contract-modal'>
                    <Checkbox onChange={onChangeCheck}> </Checkbox>
                    <Button onClick={() => showModal(1)}>Üyelik Sözleşmesi</Button>’ni, <Button onClick={() => showModal(2)}>Aydınlatma Metni</Button>'ni ve <Button onClick={() => showModal(3)}> Açık Rıza Metni</Button>'ni okudum, anladım ve onaylıyorum. Kurumunuz ile paylaşmış olduğum iletişim bilgilerim ile elektronik iletiletilerin tarafımla paylaşılmasını onaylıyorum.
                </div>

              <Form.Item style={{marginTop: 30}}>
                <Button
                  className="login-btn"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isLoding}
                  block
                  disabled={register }
                >
                  Şifremi Oluştur
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Modal className='contract-modal-style'  footer={false} open={isModalOpen} onCancel={handleCancel}>
          <Tabs activeKey={`${tabShow}`}  onChange={onChangeTab}>
            <TabPane tab="Üyelik Sözleşmesi" key='1'>
                <MembershipAgreement/>
            </TabPane>
            <TabPane tab="Aydınlatma Metni" key='2'>
                <ClarificationText/>
            </TabPane>
            <TabPane tab="Açık Rıza Metni" key='3'>
                <ExplicitConsentText/>
            </TabPane>
          </Tabs>
      </Modal>
      </Content>
    </Layout>
  );
};

export default CreatePassword;
