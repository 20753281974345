import React, { useEffect, useState}  from "react";
import { getBuyerUserDetail } from '../services/userAuth';
import { Col, Row } from "antd";



const MyAccount = () => {
 const [userDetail, setUserDetail] = useState<any>()

const buyerUserDetail = async () => {
    try{
        const response:any = await getBuyerUserDetail();
        if(response){
            setUserDetail(response.data.buyerUserDetail)
        }

    }catch(e){
        console.log(e)
    }
}

useEffect(()=>{
        buyerUserDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])

    return (
    <main>
        <Row>
            <Col>
                <h3 className="page-title">Hesap Bilgileri</h3>
            </Col>
        </Row>
        <Row>
            <Col>
                <ul className="account-div">
                    <li><span>Alıcı Ünvanı</span>{userDetail?.buyerTitle} </li>
                    <li><span>Alıcı VKN</span>{userDetail?.buyerTaxId} </li>
                    <li><span>Adı Soyadı</span>{userDetail?.nameSurname} </li>
                    <li><span>Ünvanı</span>{userDetail?.title} </li>
                    <li><span>Email</span>{userDetail?.email} </li>
                    <li><span>Telefon Numarası</span>{userDetail?.phoneNumber} </li>
            </ul>
            </Col>
        </Row>
    </main>
    )
}

export default MyAccount; 