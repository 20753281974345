import { ServiceHelper } from ".";

const approvalDetailbyId = async (processId: any): Promise<Object> => {
  return (
    await ServiceHelper.get(`supplier-financing/${processId}`, {})
  );
};

const approvebyId = async (processId: any, data: any): Promise<Object> => {
  return (
    await ServiceHelper.patch(`supplier-financing/${processId}/approve`, data)
  );
};

const discountInvoicesApi = async (): Promise<Object> => {
  return (
    await ServiceHelper.get(`supplier-financing/discountable-invoices`, {})
  );
};

const getImportTemplate = async (): Promise<Object> => {
  return (
    await ServiceHelper.get(`buyer/suppliers/sample-xlsx`, {})
  );
};

const discountInvoicesImportApi = async (formData: FormData): Promise<Object> => {
  return (
    await ServiceHelper.post(`supplier-financing/invoice/import`,formData , {})
  );
};

const getDiscountInvoicesApi = async (): Promise<Object> => {
  return (
    await ServiceHelper.get(`supplier-financing/invoice/import`, {})
  );
};

const getDashboardSummaryApi = async (): Promise<Object> => {
  return (
    await ServiceHelper.get(`dashboards/summary`, {})
  );
};

const getDownloadSampleFileApi = async (): Promise<any> => {
  return (
    await ServiceHelper.get(`invoices/sample/template`, {})
  );
};

export {
    approvalDetailbyId,
    approvebyId,
    discountInvoicesApi,
    getImportTemplate,
    discountInvoicesImportApi,
    getDiscountInvoicesApi,
    getDashboardSummaryApi,
    getDownloadSampleFileApi
}