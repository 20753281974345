import React from 'react';
import { Col, Row, Typography } from 'antd';
import { useSelector } from 'react-redux';
import LOGO_WHITE from '../assets/depario-white.png';


const { Text } = Typography;

function Footer() {
 
    const {isLoggedIn} = useSelector((state: any) => state.user);
   
    return (
     <footer>
           <Row className="footer-box">
            <Row className="footer-content">
                <Col md={4} lg={4} >
                    <div style={{  textAlign: 'left'}}>
                        <img src={LOGO_WHITE} alt="depar logo" style={{width:'200px'}} />
                    </div>                   
                </Col>     
                <Col md={20} lg={20}>
                    <div style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                    {
                            isLoggedIn ? 
                            <a href="https://cdn.depar.io/documents/depario-alici-kk.pdf#toolbar=0" target='_blank' rel="noreferrer"  
                            style={{textAlign: 'center', color: '#fff', width: '100%', textDecoration:'underline'}}>Kullanım Kılavuzu</a>
                            :
                            ''  
                        }
                        <Text style={{textAlign: 'center', color: '#fff', width: '100%'}}>
                        © 2024 – depar.io bir Neo Elektronik Ticaret Bilişim Teknolojileri Yazılım ve Danışmanlık A.Ş. kuruluşudur. Tüm hakları saklıdır.
                        </Text>
                    </div>
                </Col>         
            </Row>

            
        </Row>
     </footer>
    );
}

export default Footer;
