import { ServiceHelper } from ".";

const loginApi = async (data: any): Promise<Object> => {
  return (
    await ServiceHelper.post(`users/authenticate`, data)
  );
};

const twoFactor = async (data: any): Promise<Object> => {
  return (
    await ServiceHelper.post(`otp/validate`, data)
  );
};


const forgetPasswordApi = async (data: any): Promise<Object> => {
  return (
    await ServiceHelper.post(`buyer-user/forgot-password`, data)
  );
};

const expiredPasswordApi = async (data: any): Promise<Object> => {
  return (
    await ServiceHelper.post(`buyer-user/expired-password`, data)
  );
};


const resetPasswordApi = async (data: any, id: any): Promise<Object> => {
  return (
    await ServiceHelper.patch(`buyer-user/${id}/password`, data)
  );
};

const getBuyerUserOnboarding = async (): Promise<Object>  => {
        return await ServiceHelper.get(`buyer-user/guide-status`,
            {}
        );
};

const setBuyerUserOnboarding = async (data: any): Promise<Object>  => {
    return( await ServiceHelper.patch(`buyer-user/guide-status`,data,{})
    );
};

const getBuyerUserDetail = async (): Promise<Object>  => {
  return await ServiceHelper.get(`buyer-user`,
      {}
  );
};

const sendTermsEmailApi = async (financialInstitutionUserId: string, terms: any): Promise<Object> => {
  return (
    await ServiceHelper.post(`buyer-user/${financialInstitutionUserId}/terms`, terms)
  );
};

const passwordUpdate = async (data: any): Promise<Object>  => {
  return( await ServiceHelper.patch(`buyer-user/reset-password`,data,{})
  );
};

const logoutApi = async (): Promise<Object>  => {
  return await ServiceHelper.postt(`users/logout`, {});
};


export {
    loginApi,
    forgetPasswordApi,
    resetPasswordApi,
    getBuyerUserOnboarding,
    setBuyerUserOnboarding,
    twoFactor,
    getBuyerUserDetail,
    sendTermsEmailApi,
    passwordUpdate,
    logoutApi,
    expiredPasswordApi

}