import { ServiceHelper } from ".";

const getDashboardSummaryApi = async (): Promise<any> => {
  return (
    await ServiceHelper.get(`dashboards/summary`, {})
  );
};
const getUpComingDueInvoicesApi = async (): Promise<any> => {
  return (
    await ServiceHelper.get(`dashboards/upcoming-due-date-invoices`, {})
  );
};
const getRiskLimitApi = async (): Promise<any> => {
  return (
    await ServiceHelper.get(`dashboards/risk-limit`, {})
  );
};
const getUsedInvoicesApi = async (): Promise<any> => {
  return (
    await ServiceHelper.get(`dashboards/used-invoices`, {})
  );
};
const getUpcomingPayDateApi = async (): Promise<any> => {
  return (
    await ServiceHelper.get(`dashboards/upcoming-pay-date?pageNumber=1&pageSize=5`, {})
  );
};

export {
    getDashboardSummaryApi,
    getUpComingDueInvoicesApi,
    getRiskLimitApi,
    getUsedInvoicesApi,
    getUpcomingPayDateApi
}