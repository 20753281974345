import React, { useState, useEffect } from "react";
import {
  Row,
  Typography,
  Col,
  Form,
  notification,
  Button,
  Collapse,
  Input
} from "antd";
import { getAllSuppliersLimit, updatedLimit } from "../../services/supplierApi";
import { CheckCircleOutlined, CloseCircleOutlined, PlusCircleOutlined, MinusCircleOutlined} from "@ant-design/icons";
import { convertFloatDotSeperated } from "../../helpers/common";

const { Text } = Typography;
const Panel = Collapse.Panel;


const SupplierLimit = () => {
  const [form] = Form.useForm();
  const [supliers, setSupliers] = useState<any>([]);
  const [buyerDefinitions, setBuyerDefinitions] = useState<any>([]); 
  const [bankLimitTotal, setBankLimitTotal] = useState<any>();
  const [bankRiskTotal, setBankRiskTotal] = useState<any>();
  const [supplierCountTotal, setSupplierCountTotal] = useState<any>();
  const [inputValue, setInputValue] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
 
  interface IsupplierLimitsUpdate {      
    supplierLimits: Array<IsupplierLimits>;  
    financialInstitutionId : string   
  }
  interface IsupplierLimits {      
    supplierId: string; 
    limit: number; 
    supplierTaxNumber: string;
    financialInstitutionId : string
  }  
  let newData = {} as IsupplierLimitsUpdate;
  newData.supplierLimits = [];
  newData.financialInstitutionId = ''

  const onchangeValue = (value:any) => {
    if(value.title !== undefined){
      setSearchQuery(value.title)
    }
   
  }

  const fetchAllSuppliers  = async () => {
    try {
      
      const response: any = await getAllSuppliersLimit(searchQuery);
      if (response) {
        setSupliers(response.data.supplierLimits);
        setBuyerDefinitions(response.data.buyerDefinitions); 
      }
    } catch (error: any) {      
      console.log(error)     
      notification.error({
        message: 'Beklenmedik bir hata oluştu..',
        duration: 3,
      });
    }
  }

  const updateFinanceLimit = async (value :any, financialId:any, supplierId:any,supplierTaxId:any) => {  
    
    let newItem = {} as IsupplierLimits;
    newItem.supplierId = supplierId;
    newItem.limit = value;
    newItem.supplierTaxNumber = supplierTaxId;
    newData.supplierLimits.push(newItem);   
    newData.financialInstitutionId = financialId;
          
    if(value !== ''){
      try{
        const response: any = await updatedLimit(newData);               
        if(response?.status === 200){
            notification.success({message: 'Limit başarıyla güncellenmiştir.'});  
             window.location.reload();
           
        }         
      }catch(e:any){

        if(e.response.data.Status === 406 && e.response.data.Type.includes('BuyerLimitExcessException')){
          notification.warning({message: 'Tedarikçi Limiti Alıcı Limitinden Fazla Olamaz.'});
        }  
        if(e.response.data.Status === 406 && e.response.data.Type.includes('InvalidSupplierLimitException')){
          notification.warning({message: "Tedarikçi Limiti 0' dan küçük olamaz." });
        }    
        if(e.response.data.Status === 404 && e.response.data.Type.includes('SupplierLimitDoesNotExistException')){
          notification.warning({message: "Tedarikçi limiti tanıımlanmamıştır." });
        }     
      }     
    }else{
      notification.success({message: 'Güncellenecek limit değerini girmediniz.'});
    }    
  }

  useEffect(() => {
    fetchAllSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    setBankLimitTotal(buyerDefinitions.reduce((a:any,v:any) =>  a = a + v.limit , 0 ))
    setBankRiskTotal(buyerDefinitions.reduce((a:any,v:any) =>  a = a + v.risk , 0 ))  
    setSupplierCountTotal(buyerDefinitions.reduce((a:any,v:any) =>  a = a + v.supplierCount , 0 ))
  }, [buyerDefinitions]);

 
  const header = (supplierTitle:any,supplierTaxId:any, sumRisk:any, sumLimit:any, isRegistered:any) => {
    return (
      <div className="header" style={{display:'flex'}}>
        <div>{supplierTitle}</div>
        <div>{supplierTaxId}</div>
        <div>{convertFloatDotSeperated(sumRisk)} TL</div>
        <div>{convertFloatDotSeperated(sumLimit)} TL</div>
        <div>{sumRisk === 0 ? '-' : ((sumRisk * 100)/sumLimit)?.toFixed(2)} % </div>
        <div>{
            isRegistered 
            ? <CheckCircleOutlined style={{color: '#62b61f', fontSize: 22}} /> 
            : <CloseCircleOutlined style={{color: '#cc2c2c', fontSize: 22}} />
            }
        </div>
      </div>
    )
  }

  return (
    <main>
      <Row  className="my-suppliers">
      <Col span={24}  className="supplier-limit">       
      <Row>
        <Text className="limit-title">Limit Bölüştür</Text>
        <Text style={{fontWeight:'600'}}>Toplam limitinizi tedarikçileriniz arasında bölüştürün.</Text>       
      </Row>
      <Row>
        <Text style={{marginTop:'15px', fontSize:'20px '}}>
            Banka Risk/Limit Durumu 
          </Text>
      </Row>
      <Row className="limit-header-title">
          <Col span={24}>
              <div className="title buyer-definitions-title">
                  <div>Finans Kurumu</div>
                  <div>Mevcut Risk</div>
                  <div>Limit</div>
                  <div>Risk/ <br/> Limit %</div>
                  <div>Tedarikçi <br/> Adedi</div>
              </div>
              <div className="table-li buyer-definitions-li" style={{marginLeft:'0'}}>
                {
                  buyerDefinitions && buyerDefinitions.map((item:any,index:any) => {
                    return (
                      <div>
                        <div>{item.financialInstitutionName}</div>
                        <div>{convertFloatDotSeperated(item.risk)} TL</div>
                        <div>{convertFloatDotSeperated(item.limit)} TL</div>
                        <div>{item.risk === 0 ? '-' : ((item.risk * 100)/item.limit)?.toFixed(2)} %</div>
                        <div>{item.supplierCount}</div>                           
                    </div>
                    )
                  })
                }            
                
                <div>
                  <div>TOPLAM</div>
                  <div>{bankRiskTotal && convertFloatDotSeperated(bankRiskTotal)} TL</div>
                  <div>{bankLimitTotal && convertFloatDotSeperated(bankLimitTotal)} TL</div>
                  <div>{bankRiskTotal === 0 ? '-' : (bankRiskTotal && bankLimitTotal && (bankRiskTotal * 100)/bankLimitTotal)?.toFixed(2)} %</div>
                  <div>{supplierCountTotal && supplierCountTotal}</div>
                </div>
              </div>
          </Col>
        </Row>
      <Row style={{marginTop:'30px'}}>        
        <Text style={{marginTop:'15px', fontSize:'20px '}}>
          Tedarikçi Risk/Limit Durumu ve Güncelleme
        </Text>
        <Text style={{marginTop:'15px', fontStyle:'italic'}}><b>"Toplam Limitinizi "</b>Tedarikçileriniz arasında uygun gördüğünüz şekilde bölüştürebilirsiniz. Herhangi bir Tedarikçiye vereceğiniz limit en çok <b>“Toplam Limitiniz”</b> kadar olabilir. Ancak tüm Tedarikçilerinize toplam kullandırımınız <b>“Toplam Limitiniz”</b>i aşamaz.</Text>
      </Row>         
      <Row style={{marginTop:'20px'}}>
          <Col span={10}>
            <Form
               name="filter-box"
               onFinish={onchangeValue}
               autoComplete="off"
               labelCol={{
                 span: 8,
               }}
               size = "large"
               style={{
                 minWidth: 400,
               }}
            >
              <div className='filter-row'>                
                <div>
                  <Form.Item name="title" >
                    <Input 
                      placeholder="Tedarikçi Adı veya VKN'sini Seçiniz"
                      style={{minWidth:'450px'}}
                     />
                    </Form.Item>
                </div>               
                <div>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"                 
                    >Ara</Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Col>
      </Row>
      <div className="limit-header-title-mobil">
        <Row className="limit-header-title">
          <Col span={24}>
              <div className="title">
                  <div>Tedarikçi Adı</div>
                  <div>VKN No</div>
                  <div>Mevcut Risk</div>
                  <div>Limit</div>
                  <div>Risk/ <br/> Limit %</div>
                  <div>Platforma <br/> Kayıt Durumu</div>
              </div>
          </Col>
        </Row>
        <Row >
          <Col span={24}>
            <Collapse 
              accordion 
              // defaultActiveKey={['0']} 
              expandIcon={({ isActive }) => (
                isActive ? <MinusCircleOutlined /> :  <PlusCircleOutlined />
              )}
              className="limit-acc-style" >
              {
                supliers && supliers.map((item :any,index:any) => {
                  return(
                    <Panel 
                      className="acc-row"
                      header={header(
                        item.supplierTitle,
                        item.supplierTaxId,
                        item.sumRisk,
                        item.sumLimit,
                        item.isRegistered,
                      )} 
                      key={index}
                    >
                    <div className="table-li">                   
                      {
                        item.risksAndLimits.map((finance:any, i:any) => {
                          return(
                            <div>
                              <div></div> 
                              <div>{finance.financialInstitutionName}</div>
                              <div>{convertFloatDotSeperated(finance.risk)} TL</div>
                              <div>
                                  <Form form={form} className="supplier-limit-input" style={{display:'flex'}}>
                                    <Form.Item 
                                      name={`limit${i}` }
                                      >
                                      <Input                                        
                                        placeholder={convertFloatDotSeperated(finance.limit)} 
                                          onInput={(e:any) => {
                                            setInputValue((prevArr: any) => {
                                              const result = [...prevArr];
                                              result[i] = e.target.value;
                                              return result;
                                            });
                                          }}
                                        className="no-spin input-width"
                                        style={{borderRadius:'8px'}}
                                        type="text"
                                      />           
                                    </Form.Item>
                                  <Form.Item>
                                    <Button 
                                      className="supplier-limit-btn"
                                      onClick={() => updateFinanceLimit(
                                        inputValue[i], 
                                        finance.financialInstitutionId, 
                                        item.supplierId,
                                        item.supplierTaxId
                                        ) } 
                                    >
                                      Güncelle
                                    </Button>
                                  </Form.Item>
                                </Form>                                
                              </div>
                              <div>{finance.risk === '0' ? '-' : ((finance.risk * 100)/finance.limit)?.toFixed(2)} % </div>  
                              <div></div>                         
                            </div>
                          )
                        })
                      }
                    </div>                  
                    </Panel>
                  )
                })
              }
              </Collapse>
          </Col>       
        </Row>
      </div>      
    </Col>
      </Row>
    </main>
   
  );
};

export default SupplierLimit;
