import React, {  useState } from "react";
import { Row, Col} from "antd";
import UploadMyAgriculturist from "./UploadMyAgriculturist";
import MyAgriculturistList from "./MyAgriculturistList";

function MyAgriculturist() {
  const [active, setActive] = useState(2);

  return (
    <main>
      <Row  className="my-suppliers">
          <Col span={24} offset={1} className="my-suppliers-right">
            <Row justify="center" style={{width: '100%', marginBottom: 40}}>
              <Col
                className="tab-box"
                onClick={() => setActive(1)}
                style={{ borderColor: active === 1 ? "#0d0f7b" : "#e3ebf8", display:'none' }}
              >
                Çiftçi Yükle
              </Col>
              <Col
                className="tab-box"
                onClick={() => setActive(2)}
                style={{ borderColor: active === 2 ? "#0d0f7b" : "#e3ebf8" }}
              >
                Çiftçilerim
              </Col>
            </Row>
            
            {active === 1 ? <UploadMyAgriculturist />: <MyAgriculturistList />}
          </Col>
      </Row>
    </main>
  );
}

export default MyAgriculturist;

