import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, DatePicker, Divider, notification, Radio, Space, Spin, Table } from "antd";
import moment from "moment";
import { ISupplierDetail } from "../entities/ISupplierDetail";
import { getStatusClassName } from "../helpers/getStatusClassName";
import { EFundStatus } from "../entities/ISupplier";
import { approvalDetailbyId, approvebyId } from "../services/financingApi";
import { convertCurrencyTl } from "../helpers/common";
import Header from "../components/Header";
import Preview from "../components/Preview";

export interface IState {
  detail?: ISupplierDetail;
  isLoading?: boolean;
  showModal?: boolean;
  invoiceImage?: string;
  newStatus?: EFundStatus;
}

const ApprovalReqDetail = () => {
  const location = useLocation();
  const [detail, setDetail] = useState<ISupplierDetail>();
  const [newStatus, setNewStatus] = useState<EFundStatus>();
  const [invoicesData, setInvoicesData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const processId = location.pathname.split('/')[2];

  const loadDetails = async () => {
    setIsLoading(true);
    try {
      const response: any = await approvalDetailbyId(processId)
      if (response?.status === 200) {
        setIsLoading(false);
        setInvoicesData([]);
        setDetail(response.data.data)
        setInvoicesData(response.data.data.invoices);
      }
    } catch (error: any) {
      setIsLoading(false);
      notification.error({
        message: error.response.data.message,
        duration: 3,
      })
    }
  }

  useEffect(() => {
    loadDetails();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const renderCol = (value: any, title: string, key: string) => {
    return <div key={key} className="col">
      <span className="title" >{title}</span>
      <span className="value">{value}</span>
    </div>
  }

  const handleRequest = async () => {
    setIsLoading(true);
    try {
      if(newStatus === EFundStatus.Approved) {
        const emptyValues = invoicesData.find((value: any) => value.invoiceDueDate === null);
        if (emptyValues) {
          setIsLoading(false);
          notification.error({ 
            message: 'Lütfen fatura ödeme vadesini giriniz', 
            duration: 3, 
          });
          return;
        }
        const filterData = invoicesData.reduce((newData: any, invoice: any) => {
          newData.push({ ...invoice, invoiceDueDate: moment(invoice.invoiceDueDate).format()})
          return newData;
        }, [])
      
        const response: any = await approvebyId(processId, {
          isApproved: true,
          invoices: filterData,
        })

        if (response?.status === 200) {
          setIsLoading(false);
          window.location.reload();
        }
      }
      if (newStatus === EFundStatus.Expired) {
        const response: any = await approvebyId(processId, {
          isApproved: false,
        })
        if (response?.status === 200) {
          setIsLoading(false);
          window.location.reload();
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      notification.error({
        message: error.response.data.message,
        duration: 3,
      })
    }
  }

  const renderNewDemandFooter = () => {
    return <>
      <span className="newDemandText">Yukarıdaki fatura/ların tedarikçi finansmanı işlemlerinde kullanılmasını onaylıyor musunuz?</span>
      <Radio.Group onChange={(event: any) => {
          setNewStatus(event.target.value)
        }} value={newStatus}>
        <Space direction="vertical">
          <Radio value={EFundStatus.Approved}>Onayla</Radio>
          <Radio value={EFundStatus.Expired}>Reddet</Radio>
        </Space>
      </Radio.Group>
      {/* {newStatus === EFundStatus.Approved && ( 
        <div>
          <span className="newDemandText"> Lütfen fatura ödeme vadesini giriniz.</span>
          <DatePicker onChange={setDate} value={date} size="large" className="detailsDatePicker" format={"DD-MM-YYYY"} ></DatePicker>
        </div>
      )} */}
      <Button type="primary" onClick={handleRequest} className="detailsButton">Gönder</Button>
    </>
  }
    
  const renderFooter = () => {
    switch (detail?.status?.id) {
      case EFundStatus.Pending:
        return renderNewDemandFooter();
    }
  }

  const handleInvoiceData = (e: any, index: number) => {
    invoicesData[index].invoiceDueDate = e;
    setInvoicesData([...invoicesData]);
  }

  let statusClassName = "default " + getStatusClassName(detail?.status);
  return (
    <>
      <Header />
      <Spin spinning={isLoading} wrapperClassName={"detailSpin"}>
        <span className={"tableTitle"}>Fatura Onay Talepleri</span>
        <span className={statusClassName}>{detail?.status?.text || ''} </span>
        <Divider />
        <span className={"detailsTitle"}>Talep Detayı</span>
        <div className="detailRow">
          {renderCol(detail?.id, "Başvuru No:", "basvuruNo")}
          {renderCol(detail?.demandant || "-", "Talep Eden:", "talepEden")}
          {renderCol(moment(detail?.requestDate).format("DD-MM-YYYY"), "Talep Tarihi:", "talepTarihi")}
          {renderCol(convertCurrencyTl(detail?.invoiceTotal || 0), "Toplam Fatura Tutarı:", "toplamTutar")}
          {renderCol(detail?.invoices?.length, "Toplam Fatura Adedi:", "toplamAdet")}
        </div>
        <Divider />
        <Table
          pagination={false}
          rowKey={"index"}
          dataSource={detail?.invoices?.map((i, index) => { return { ...i, index: index + 1 } })}
          columns={[
            {
              title: '',
              dataIndex: 'index',
              key: 'index',
            },
            {
              title: 'Fatura No',
              dataIndex: 'invoiceNo',
              key: 'invoiceNo',
              width: 180,
            },
            {
              title: 'Fatura Tarihi',
              dataIndex: 'invoiceDate',
              key: 'invoiceDate',
              render: (value: string) => {
                return moment(value).format("DD-MM-YYYY");
              }
            },
            {
              title: 'Tedarikçi VKN',
              dataIndex: 'debtTaxId',
              key: 'debtTaxId',
            },
            {
              title: 'Fatura Tutarı',
              dataIndex: 'invoiceAmount',
              key: 'invoiceAmount',
              render: (value: number) => {
                return <div>{convertCurrencyTl(value)}</div>
              }
            },
            {
              title: '',
              dataIndex: 'invoiceImage',
              key: 'invoiceImage',
              render: (value: any, record: any, index: any) => ( 
                <div>
                  {value.includes('pdf') ? (
                      <Preview src={value} />
                    ) : (
                      <Preview src={value} />
                    )  
                  }
                </div>
              )
            },
            {
              title: detail?.status?.id !== EFundStatus.Rejected ? 'Fatura Vadesi' : '',
              dataIndex: 'invoiceDueDate',
              key: 'invoiceDueDate',
              render: (value: any, record: any, index: any) => {
                return newStatus === EFundStatus.Approved ?
                ( 
                  <div>
                    {invoicesData.length > 0 && (
                      <>
                        <span className="newDemandText"> Lütfen fatura ödeme vadesini giriniz.</span>
                        <DatePicker onChange={(e) => handleInvoiceData(e, index)} value={value === null ? value : moment(value)} size="large" className="detailsDatePicker" format={"DD-MM-YYYY"} />
                      </>
                    )}
                  </div>
                ) : (
                  <div>{value === null ? '' : (detail?.status?.text && moment(value).format("DD-MM-YYYY"))}</div>
                ) 
              }
            },
          ]} />
        {renderFooter()}
      </Spin>
    </>
  );
}


export default ApprovalReqDetail;