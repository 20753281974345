import React, {useEffect, useState, useRef} from "react";
import { useIdleTimer } from 'react-idle-timer' 
import { Row, Button, Modal, Typography } from "antd";
import { Outlet } from 'react-router-dom';
// import Footer from "../components/Footer";
import Header from "../components/Header";
import Joyride from 'react-joyride';
import { getBuyerUserOnboarding, setBuyerUserOnboarding } from '../services/userAuth';
import { LocalStorageService } from "../helpers/LocalStorage";

const { Text } = Typography;

const steps: any = [
  {
    target: '.fatura-yukle',
    disableBeacon: true,
    content: 'Tedarikçinize ait olan faturalarınızı “Fatura Yükle” menüsünden yükleyebilirsiniz.',
  },
  {
    target: '.fatura-durum',
    content: 'Yüklediğiniz faturalarınızı tedarikçilerin nasıl kullandığını  bu alandan takip edebilirsiniz.',
  },
  {
    target: '.temlik-fatura',
    content: 'Tedarikçinin ödeme talep ettiği faturalarına bu alandan hızlıca ulaşabilirsiniz.',
  },
  {
    target: '.vadesi-fatura',
    content: 'Ödemesi yaklaşan faturalarınıza buradan ulaşın.',
  },
  {
    target: '.excel-yukle',
    content: 'Tedarikçinize ait olan faturalarınızı excel dosyası olarak yüklemek için  “Fatura Yükle”menüsünden “Excel Dosyası Yükle” seçeneğini seçebilirsiniz.',
  },
  {
    target: '.secure-yukle',
    content: 'Tedarikçinize ait olan faturalarınızı excel dosyası olarak yüklemek için  “Fatura Yükle”menüsünden “Secure Ftp Dosyası Yükle” seçeneğini seçebilirsiniz.',
  },
];

let isCompleted = false;

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRun, setIsRun] = useState(false);
  const [isStatus, setIsStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sessionTimeOutRef:any = useRef(null)

  const setOnboardStatus = async (data: string) => {
    await setBuyerUserOnboarding({"guideStatus":data});
  }

  const handleHint = (run: boolean) => {
    setIsRun(run);
    setIsOpen(false)
  }

  const getOnboardStatus = async () => {
    const response: any = await getBuyerUserOnboarding();
    setIsStatus(true);
    if (response.data.guideStatus === 'None' || response.data.guideStatus === 'Skipped') {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }

  const handleSteps = (e: any) => {
    if(e.index === steps.length - 1 && !isCompleted) {
      setOnboardStatus('COMPLETED')
      isCompleted = true;
    }//TODO setOnboardStatus triggers itself 4 times stopped by boolean -Ali Okan Kara
  }

  useEffect(() => {
    if (!isStatus) {
      getOnboardStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

 

  const onIdle = () => {
    setIsModalOpen(true)
    sessionTimeOutRef.current = setTimeout(handleExit, 5000)
  }
  const handleStay = () => {
    setIsModalOpen(false);
    clearTimeout(sessionTimeOutRef.current)
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1000 * 5 * 60,
    throttle: 500
  })

  const handleExit = () => {
    setIsModalOpen(false)
    LocalStorageService.removeAuthToken();
    LocalStorageService.removeAuthName();
    LocalStorageService.removeBuyerType();
    window.location.reload();
 
 };
  
  useEffect(() => {
    const interval = setInterval(() => {
      getRemainingTime()
    }, 500)
    return () => {
      clearInterval(interval)
    }
  })

  return (
    <>
      <Header/>
      <Outlet />
      {/* <Footer /> */}
      <Modal 
        title="Depar.io'a Hoş Geldiniz  🎉" 
        open={isOpen} 
        footer={null}
        onOk={() => setIsOpen(false)} 
        onCancel={() => setIsOpen(false)}
      >
        <Text style={{fontSize: 16, marginTop: 10}}>Depar'ı nasıl kullanacağınızı 6 adımda öğreninin</Text>
        <Row>
          <Button type="default" onClick={() => handleHint(false)} className="hint-modal-btn skip-btn">Geç</Button>
          <Button type="primary" onClick={() => handleHint(true)} className="hint-modal-btn start-btn">Başla</Button>
        </Row>
      </Modal>
      <Joyride 
        steps={steps}
        showProgress
        continuous
        callback={handleSteps}
        run={isRun}
        hideBackButton
        scrollToFirstStep
        showSkipButton={false} 
        locale={
            {
                next: `Sonraki`,
                last: `Sonraki`,
            }
        }
        styles={{
          buttonNext: {
            fontSize: '.75rem',
            width: '141px',
            height: '40px',
            color: '#fff',
            backgroundColor: '#0d0f7b',
            borderRadius: 8,
            padding: '10px',
          },
          buttonClose: {
            width: '11px',
            marginTop: 4
          },
          beacon: {
            display: 'none',
            outline: 'none',
            left: -60,
          }
        }} 
      />
      <Modal  
          open={isModalOpen} 
          className='modal-login-logout'
          footer={[
            <Button key="back" style={{backgroundColor:'#504d4d'}} onClick={handleExit}>
              Oturumu kapat
            </Button>,
            <Button key="back" style={{backgroundColor:'#0d0f7b'}} onClick={handleStay}>
              Oturumu açık tut
            </Button>
          ]}
          >
            <p style={{fontSize:'15px', textAlign:'center', marginBottom:'0', fontWeight:'500'}}>Bir süredir hareketsizsiniz! Ne yapmak isterseniz?</p>
      </Modal>
    </>
  )
};
export default Home;
