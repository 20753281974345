import { createSlice } from '@reduxjs/toolkit';

interface TabState {
  activeTab: number;
}

const initialState = {
  activeTab :0
} as TabState;

const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    activeTab(state,action){
      state.activeTab = action.payload
    }
  },
});

export const {  activeTab } = tabSlice.actions;
export default tabSlice.reducer;
