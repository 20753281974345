import React from 'react';
import { Col, Row, Form, Input, notification, Typography, Button } from 'antd';
import {passwordUpdate} from "../services/userAuth";
import { validatePassword } from '../helpers/validators';

const { Text } = Typography;

const PasswordUpdate = () => {
    const [form] = Form.useForm();

    const updatePassword = async ( values:any ) => {
        try{
            if(values.newPassword === values.confirmNewPassword){
                const response:any =  await passwordUpdate(values);
                if(response.status === 204){
                    notification.success({
                        message: `Başarıyla şifreniz güncellenmiştir.`,
                    });
                    form.resetFields(); 
                }
            }else{
                notification.warning({
                    message: `Yeni şifre ile şifre onayınız aynı olmalıdır.`,
                });
            } 
        }catch(e:any){
            if(e.response.data.Status === 400 && e.response.data.Type.includes('IncorrectOldPasswordException')){
                notification.error({
                    message: `Eski şifreniz yanlıştır.`,
                });
            }
            if(e.response.data.Status === 409 && e.response.data.Type.includes('NewPasswordCannotBeOldPasswordException')){
                notification.error({
                    message: `Yeni şifre eski şifrenizle aynı olamaz.`,
                });
            }
        }
        
    };

    return (
        <main>
         <Row style={{ marginTop: 50 }}>
            <Col span={24}>
                <h3 className="page-title"> Şifre Değiştir</h3>
            </Col>
         </Row>
        <Row>
            <Col xs={24} md={10} lg={7} >
                <Text style={{ marginBottom: '20px', display: 'block', marginTop: '20px' }}>Yeni şifre oluşturunuz.</Text>

                    <Form
                        name="sendCode"
                        onFinish={updatePassword}
                        className="d-block password-update email-input"
                        labelCol={{ span: 5 }}
                        layout="horizontal"
                        form={form}>
                        <Form.Item
                            name="oldPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Değiştirmek istediğiniz şifrenizi giriniz.'
                                },
                            ]}
                        >
                            <Input.Password placeholder="Şifreniz" />
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            rules={[
                                    {
                                        required: true,
                                        validator: (_, value) => validatePassword(value),
                                    },
                                ]}
                        >
                            <Input.Password placeholder="Yeni Şifre" />
                        </Form.Item>
                        <Form.Item
                            name="confirmNewPassword"
                            rules={[
                                    {
                                        required: true,
                                        validator: (_, value) => validatePassword(value),
                                    },
                                ]}
                        >
                            <Input.Password placeholder="Yeni Şifre Onayla" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                style={{ width: '200px', margin: '20px auto', display: 'block' }}
                                // loading={isResetPasswordSendCodeLoading}
                                block>
                                Gönder
                            </Button>
                        </Form.Item>
                    </Form>

                </Col>
            </Row>
    </main>
     
    );
}

export default PasswordUpdate;
