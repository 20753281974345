import React from 'react';
import { Row} from 'antd';



const ExplicitConsentText = () => (
   <div  className='membership-modal'>        
      <Row>
         <h3 className='title-center'>Neo Elektronik Ticaret Bilişim Teknolojileri Yazılım ve Danışmanlık Anonim Şirketi
Kişisel Verilerin Korunması Kanunu ile İlgili Açık Rıza Beyanı</h3>
         <p>
         Neo Elektronik Ticaret Bilişim Teknolojileri Yazılım ve Danışmanlık Anonim Şirketi (“Şirket”) Kişisel Verilerin Korunması ve Aydınlatma Metni’ni okudum ve bu kapsamda; 
         </p>
         <p>
         Şirket’in ürün ve hizmetlerinin geliştirilmesi, beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek tarafıma önerilmesi, müşteri portföyü oluşturulması, Şirket ile iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini, Şirket tarafından yürütülen iletişime yönelik idari operasyonlar, Şirket’e ait lokasyonların fiziksel güvenliğinin ve denetiminin sağlanması, Müşteri değerlendirme/şikâyet yönetimi süreçleri, itibar araştırma süreçleri, hukuki uyum süreci, denetim v.b. süreçlerinin yürütülmesi, Şirket’in ticari ve iş stratejilerinin belirlenmesi ve uygulanması amaçları için aşağıdaki tabloda (Tablo-1) yer al alan Veri Kategorileri kapsımda yer alan Kişisel Verilerimin bu kapsamda depolamasına, arşivlenmesine, analiz edilmesine, mevcut bilgi ve/veya verilerim üzerinden kurum ve/veya şahsımın finansal geçerliliğinin rakamsal, sayısal, yazı ve grafiksel olarak analiz edilip yorumlamasına, bu bilgi ve detayların kaydedilmesine, loglanmasına, saklanmasına, güncellenmesine, işlenmesine, üçüncü kişilerle paylaşılmasına, üçüncü kişilere açıklanabilmesine, devredilebilmesine, sınıflandırılabilmesine, 6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK)’nın kişisel verilerin aktarılmasına ilişkin 8. maddesi ve kişisel verilerin yurtdışına aktarılmasına ilişkin 9. maddesinde belirtilen şartlar çerçevesinde ve yine 6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK)’nda sayılan şekillerde izlenebilmesine açık rıza verdiğimi beyan ederim. 
         </p>
         <p style={{textAlign:'center',width:'100%'}}> Tablo-1 </p>
         <div>
         <table style={{width:'100%'}}>
               <thead>
                  <tr>
                     <th style={{textAlign:'center'}}>Veri Kategorisi</th>
                     <th style={{textAlign:'center'}}>Kişisel Veri</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>Kimlik</td>
                     <td>Ad-Soyad, TCKN vb.</td>
                  </tr>
                  <tr>
                     <td>İletişim</td>
                     <td>Adres no, e-posta adresi, iletişim adresi, telefon no v.b.</td>
                  </tr>
                  <tr>
                     <td>Lokasyon</td>
                     <td>Bulunduğu yerin konum bilgileri v.b.</td>
                  </tr>
                  <tr>
                     <td>Müşteri işlem  </td>
                     <td>Çağrı/İletişim merkezi kayıtları, fatura, imza beyannamesi, vergi levhası  v.b.</td>
                  </tr>
                  <tr>
                     <td>İşlem Güvenliği</td>
                     <td>IP adresi bilgileri, İnternet sitesi giriş çıkış bilgileri, Şifre ve parola bilgileri v.b.</td>
                  </tr>
                  <tr>
                     <td>Finans</td>
                     <td>Kredi ve risk bilgileri vb.</td>
                  </tr>
                  <tr>
                     <td>Pazarlama</td>
                     <td>Anket, Çerez kayıtları, Kampanya çalışmasıyla elde edilen bilgiler v.b.</td>
                  </tr>
                  <tr>
                     <td>Görsel ve İşitsel Kayıtlar</td>
                     <td>Görsel ve İşitsel kayıtlar v.b.(örn. çağrı merkezi kayıtları, görüntülü görüşme kayıtları, fotoğraf vb.)</td>
                  </tr>
               </tbody>
            </table>
         </div>
         <p style={{width:'100%', marginTop:'20px'}}>Tarih:</p>
         <p style={{width:'100%'}}>Ad Soyad:</p>
      </Row>
         
           
   </div>
    
)
export default ExplicitConsentText;