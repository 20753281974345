import React from 'react';
import { Row} from 'antd';

const MembershipAgreement = () => (

        <div  className='membership-modal'>        
           <Row>
            <h3 style={{width:'100%'}} className='title-center'>DEPAR.İO PLATFORM ALICI KULLANIM SÖZLEŞMESİ</h3>
            <ol>
                <li>
                <span>Sözleşme’nin Tarafları</span>
                <p>
                <span>1.1.</span> Bu Depar.io Platform Kullanım Sözleşmesi (“Sözleşme”), Maslak Mahallesi, Büyükdere Cadde, Nurol Plaza Sitesi, No: 255/B02, Sarıyer, İstanbul adresinde yerleşik Mersis Numarası 0631-1839-7340-0001 olan Neo Elektronik Ticaret Bilişim Teknolojileri Yazılım ve Danışmanlık A.Ş. (“Depar.io”) ile [•] adresinde yerleşik Mersis Numarası [•]olan [•] (“Alıcı”) arasında tanzim edilmiştir.
                </p>
                </li>
                <li>
                <span>Sözleşmenin Konusu ve Düzenlediği İlişki</span>
                <p>
                <span>2.1.</span>	Bu Sözleşme, Alıcı’yı, Alıcı’ya mal ya da hizmet satan satıcılar (“Tedarikçi”) ile Tedarikçi’nin Alıcı’ya düzenlediği faturalardan doğan alacaklarını devralıp bu alacakları devralmak suretiyle Tedarikçi’ye finansman sağlayacak (“Tedarikçi Finansmanı”) bankayı (“Banka”) Depar.io tarafından yaratılmış ve işletilmekte olan <a href='https://depar.io/' >www.depar.io</a>  adresli web sitesi ortamında (“Platform”) bir araya getiren sistemin Alıcı tarafından kullanım koşullarını düzenlemektedir. 
                </p>
                <p>
                <span>2.2.</span>	Depar.io ve Alıcı’dan herhangi biri “Taraf”, her ikisi birlikte “Taraflar” olarak ifade edilebilecektir.
                </p>
                <p>
               <span>2.3.</span> Platform’u kullanmak isteyen Alıcı, bu Sözleşme’yi imzalayarak aşağıda tarif edilen koşulları yerine getirerek Platform’un üyesi (“Üye”) statüsünü kazanacaktır. Platform’a üye olma koşullarını ayrı bir sözleşme ile yerine getiren Tedarikçi ve Banka da Üye statüsünü kazanacaktır. Bu Sözleşme’nin farklı yerlerinde “Üye” ile ifade edilecek kişi yerine göre Alıcı, Banka, Tedarikçi ya da bunların ikisi birlikte olabilecektir. 
                </p>
                </li>
                <li>
                <span>Platform’un Amacı ve İşleyişi</span>
                <p>
                <span>3.1.</span>	Tedarikçi, sunduğu hizmet ya da tedarik ettiği mal için Alıcı’ya düzenlediği faturalardan doğan vadeli alacaklarını Banka’ya devrederek, bunun karşılığında Tedarikçi Finansmanı sağlamayı hedeflemektedir.
                </p>
                <p>
                <span>3.2.</span>	Banka, Tedarikçi tarafından kendisine devredilecek alacak karşılığında Tedarikçi’ye finansman sağlamak istemektedir.
                </p>
                <p>
                <span>3.3.</span>Alıcı, Tedarikçi’nin kendisinden olan faturaya dayalı vadeli alacaklarının devrine izin verdiklerinin Banka tarafından iskonto edilmesi suretiyle Tedarikçi’nin finansmana erişimine destek olmak istemektedir.
                </p>
                <p>
               <span>3.4.</span> Platform, Alıcı, Tedarikçi ve Banka’yı bir araya getirerek bunlar arasındaki iletişimin eş zamanlı, zaman tasarruflu ve efektif şekilde sağlanmasına yönelik hizmet sunmaktadır.
                </p>
                <p>
               <span>3.5.</span> Alıcı, Tedarikçi’nin kendisine düzenlediği faturaları Platform’a yükleyecektir. Alıcı’nın Platform’a bilgisini yüklemediği Tedarikçi Platform’da işlem yapamayacak, Platform’a yüklenmemiş Tedarikçi faturaları için de Platform’da işlem yapılamayacaktır.
                </p>
                <p>
               <span>3.6.</span> Tedarikçi, Platform'a giriş̧ yaptıktan sonra Alıcı tarafından Platform’a yüklenmiş̧ ve finansman için kullanabileceği faturalardan, Alıcı'nın kendisi için tanımlamış olduğu limit çerçevesinde, seçimlerini yaparak seçeceği Banka’ya Tedarikçi Finansman’ı başvurusu yapacaktır.
                </p>
                <p>
               <span>3.7.</span> 	Tedarikçi’nin başvurusu üzerine Platform tarafından kendisine bir başvuru numarası iletilecektir. Tedarikçi, bu başvuru numarasıyla, yapmış olduğu başvurunun sürecini Platform üzerinden takip edebilecektir. Tedarikçi, Banka’nın kendisine yapacağı finansman teklifini kabul ya da reddedebilecektir. Tedarikçi’nin Banka’nın teklifini reddetmesi halinde başka bir işlem yapmasına gerek kalmayacaktır.
                </p>
                <p>
               <span> 3.8.</span>	Tedarikçi, Banka’dan gelen teklifi kabul edecek olması halinde başvuru detayları içerisinden teklifi kabul et seçeneğini işaretleyecektir. Bunun sonucu olarak da Banka’ya iletilmek üzere, Tedarikçi Finansmanı işlemine konu Alacak Bildirim Formu (“ABF” (“Temlikname”) Platform tarafından otomatik olarak oluşturulacaktır.
                </p>
                <p>
               <span>3.9.</span> Tedarikçi, Platform tarafından otomatik olarak oluşturulan finansman başvurusuna ait ABF'yi imzaladıktan sonra taranmış halini Platform'a yükleyecektir. ABF’nin ıslak imzalı halinin Banka’ya iletilme sorumluluğu Tedarikçi’nindir.
                </p>
                <p>
               <span>3.10.</span> Böylelikle Tedarikçi ve Banka alacağın devri, finansman sözleşmesi ve bu kapsamda mevzuat uyarınca yerine getirilmesi gereken diğer işlemler öncesinde belirli prensiplerde mutabakat sağlamış olacaklar, bu ve benzeri diğer hukuki süreçler bakımından hazırlık işlemlerini tamamlamış olacaklardır.
                </p>
                <p>
               <span>3.11.</span> Bu Sözleşme, Platform’a üyelik ya da Platform aracılığı ile gerçekleştirecekleri işlemler Banka ile Tedarikçi arasında alacağın devrinin gerçekleşeceğine, finansman sözleşmesinin imzalanacağına ya da diğer hukuki işlemlerin tesis edileceğine yönelik Depar.io’nun bir taahhüdünü içermemektedir. Depar.io, bu Sözleşme kapsamında Platform’un sağlıklı şekilde çalışması haricinde Alıcı’ya başkaca bir taahhütte bulunmamaktadır.
                </p>
                <p>
               <span>3.12.</span> Depar.io Üye’lerden herhangi birisi adına bir taahhütte bulunmamakta veya onları hiçbir sıfatla temsil etmemekte, tamamen kendi adına ve hesabına hareket etmektedir.
                </p>
                </li>
                <li>
                <span>Alıcı’nın Platform Üyeliğinde İzleyeceği Yol ve Yerine Getirmesi Gereken Diğer Koşullar</span>
                <p>
              <span>4.1.</span>  Alıcı, Platform’u kullanabilmek için Depar.io’ya bir e-posta adresi iletecektir. Depar.io, kendisine iletilen e-posta ile Alıcı'yı Platform’a tanımlayacak ve Alıcı adına Platform’da işlem yapmaya yetkili kişiye özel bir kullanıcı hesabı oluşturacaktır. Paylaşılan e-posta adresi ile kullanıcı hesabı oluşturulduktan sonra, kullanıcının e-posta adresine şifre oluşturmak için bir e-posta Platform tarafından otomatik olarak gönderilir. Alıcı, bu e-posta ile sadece kendisine ait olacak şifreyi oluşturur ve Depar.io’ya bildirdiği e-posta adresini kullanıcı adı olarak kullanarak Platform’u kullanabilecektir.   
                </p>
                <p>
               <span>4.2.</span> 	Alıcı, Platform’a giriş yapmak istediğinde, kendisine ait e-posta adresine yeni ve özgün bir altı haneli doğrulama kodu gönderilir. Böylelikle Platform’a giriş yapmak isteyenin Alıcı olduğuna dair teyit sağlanmış olacaktır.
                </p>
                <p>
              <span>4.3.</span>  Alıcı, Platform’a erişim sağladıktan sonra Tedarikçi Finansmanı’na erişmesini istediği Tedarikçi’ye ait bilgileri, tek tek ya da toplu olarak Platform'a yükleyecektir. Alıcı, Tedarikçi’ye yönelik Ad-Soyad/Unvan, E-Posta Adresi, Telefon Numarası, Adres, Vergi Dairesi, Vergi Numarası bilgilerini Platform’a yükleyecektir.
                </p>
                <p>
               <span>4.4.</span> 	Alıcı, Platform’a bilgilerini yüklediği Tedarikçi için Platform’a Üye Banka'da kullanabileceği bir kredi üst limiti belirleyecektir. Alıcı’nın Tedarikçileri için belirleyeceği limitlerin toplamı Banka’nın Alıcı için belirlemiş olduğu toplam limiti aşamayacaktır. Alıcı ise Banka’nın kendisine tanımış olduğu toplam limit içerisinde kalmak kaydı ile Tedarikçilerden her biri için dilediği limiti tayin edebilecektir. Tedarikçi, Alıcı’nın kendisi için Platform’a tanımladığı limiti aşar şekilde bir Tedarikçi Finansmanı talebinde bulunamayacaktır.
                </p>
                <p>
               <span>4.5.</span> Alıcı, Platform’a üyelik için Tedarikçi’den Depar.io’ya iletilmek üzere temin edeceği bilgilerin doğru olacağına, Tedarikçi tarafından belirlenecek ve Platform’u Tedarikçi’yi temsilen kullanacak kişinin platform kullanım sözleşmesini Platform’a üyelik aşamasında online şekilde kabul etmek suretiyle Tedarikçi adına imzalamaya yetkilendirilmiş sayılacağına yönelik taahhütleri Tedarikçi’yi temsil ve ilzama yetkili temsilciden alacaktır.
                </p>
                </li>
                <li>
                <span>Platform’un Kullanılış Şekli</span>
                <p>
             <span> 5.1.</span>	Üye, Platform’u, kendilerine ait Üyelik Hesabı’na giriş yaparak kullanacaktır. Üyelik Hesabı’nın yaratılması, Üye’ye, Platform’u kullanma ve belirlenen kurallara göre Platform üzerinden birbirleri ile iletişimde bulunma imkânı sağlayacaktır.
                </p>
                <p>
              <span>5.2.</span> Üye’nin Depar.io’ya üyelik başvurusu sırasında veya sonrasında ilettiği ya da onun adına bir başka Üye tarafından iletilen bilgilerin hatalı, eksik, gerçeğe aykırı veya yanıltıcı olmasından kaynaklanan tüm zararlardan Üye sorumlu olacaktır. 
                </p>
                <p>
              <span>5.3.</span> Platform’a Üye olunması, Üye’nin Platform’u her koşulda kesintisiz ve süresiz kullanabileceği anlamına gelmemektedir. Depar.io, yapacağı değerlendirmeye bağlı olarak her zaman Platform’un kullanımını kısıtlama, Üye’ye erişimi engelleme hakkına sahip olacaktır.
                </p>
                <p>
              <span>5.4.</span> 	Üye’nin kendisine ait kullanıcı adı ve şifresinin güvenlik sorumluluğu Üye’ye aittir. Depar.io’nun kullanıcı adı ve şifrenin güvenliğine yönelik Üye’ye maskeleme yöntemi ile koruma sağlama dışında herhangi bir taahhüdü bulunmamaktadır. Depar.io, Üye’nin Platform’a kullanıcı adı ve şifre ile bağlandıklarında Platform’u kullananın Üye’nin kendisi olduğunu addedecektir.
                </p>
                <p>
               <span>5.5.</span> Üye, bu Sözleşme kapsamında gerek üyelik oluşturulur iken gerekse de Sözleşme’nin kabulünden sonra Platform’un kullanımı sırasında Depar.io’ya ya da Platform aracılığı ile Üyeler’e aktarılan kişisel verilerin işlenebildiği veya aktarılabildiği bilinci ile hareket etmekte, bu işlemlerin 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ilgili mevzuata uygun olarak gerçekleştirildiğine yönelik açıkça bilgilendirilerek ve aydınlatma metnini okuyarak hareket etmektedirler.
                </p>
                <p>
               <span> 5.6.</span>		Üye, Üyelik Hesabı’na erişim için kullanacağı e-posta adresini ve şifreyi her zaman güvenli şekilde saklamakla yükümlüdür. Üye, e-posta adresi veya şifresinin çalınması veya kaybedilmesi veya Üye Hesabı’nın üçüncü kişiler tarafından izinsiz kullanıldığına yönelik bir şüphenin varlığı halinde bu durumu gecikmeksizin Depar.io’ya bildireceklerdir. Bu şekilde bildirimin yapılmaması veya Üye’nin e-posta veya şifre bilgilerinin Üye tarafından üçüncü kişilerle paylaşılması halinde, Üye’nin Üyelik Hesabı kullanarak gerçekleştirilen işlemlerden, buna bağlı oluşacak zarar veya borçlardan Depar.io’nun bir sorumluluğu doğmayacaktır.
                </p>
                </li>
                <li>
                <span> Taraflar’ın Birbirlerine Karşı Taahhütleri</span>
                <p>
               <span>6.1.</span> 	Platform, Üyeler’in birbirleriyle bu Sözleşme’de Platform’un fonksiyonuna yönelik yapılan açıklamalar uyarınca Tedarikçi Finansmanı işlemlerinin bir kısmını gerçekleştirmek amacıyla tasarlanmış olup, Üye’ler arasında Tedarikçi Finansmanı için gereken asıl sözleşmeler bu Sözleşme’den ayrı olarak tanzim ve imza edilecektir.
                </p>
                <p>
               <span>6.2.</span> Platform’da kullanılan tüm grafik, görüntü, ses ya da şekiller fikri ya da sınai bir hakka konu olup olmadığına bakılmaksızın, Depar.io’nun mülkiyetindedir ve Depar.io bunları dilediği zaman değiştirebilecektir. Üyeler, Platform’un herhangi bir özellik ya da yönünü, hiçbir surette kopyalayamayacak, örnekleyemeyecek ya da paylaşamayacaktır.
                </p>
                <p>
              <span>6.3.</span> Depar.io’nun Platform’a Üyelerce yüklenen her türlü bilgi ve belgenin doğruluğuna, amacına hizmet edip etmediğine, Üyeler’in hedeflediği amaç için yeterli olup olmadığına dair bir sorumluluğu yoktur. Platform’daki bilgi ve belgelerin değerlendirmesi Platform’u bu Sözleşme’nin amacına uygun şekilde kullanan ilgili Üye ya da Üyelerce yapılacaktır.
                </p>
                <p>
               <span>6.4.</span> 	Üyeler, Üyelik Hesabı’nı devredemeyecekler, Platform’u kullanır iken yürürlükteki mevzuata uygun şekilde hareket edecekler, Platform’un sağlıklı şekilde hizmet vermesini engelleyici nitelikte ya da onu sabote edici türde girişimde bulunmayacaklar, Platform’un içeriğini kopyalamayacaklar veya dağıtmayacaklardır. Üyeler, Platform’a doğru bilgi ve belge yükleyecekler, kendisi dışındaki Üyeler’i yanıltmak, iradesini sakatlamak amacıyla hareket etmeyeceklerdir.
                </p>
                <p>
              <span>6.5.</span> 	Bu Sözleşme kapsamında Finansman’a konu edilebilecek faturalar, Gelir İdaresi Başkanlığı tarafından onaylanmış ve ilgili mevzuata uygun şekilde olmalıdır. Aksi halde ilgili fatura Platform’a kaydedilmez ve herhangi bir Finansman’a konu edilemez. 
                </p>
                </li>
                <li>
                <span>Depar.io’un Taahhütleri</span>
                <p>
              <span>7.1.</span> Depar.io, Platform’a Üye tarafından yüklenen bilgi ve belgelerin doğruluğunu, içeriklerinin yeterliliğini, Üye’nin diğer bir Üye’ye karşı olan edimini taahhüt ya da garanti etmemektedir.
                </p>
                <p>
               <span>7.2.</span> 	Depar.io’nun Üye’ye karşı tek taahhüdü, Platform’u çalışır şekilde Üye’nin kullanımına hazır bulundurmaktır. Bu noktada Depar.io’nun elinde olmayan nedenlerle Platform’un hizmet veremediği zamanlar için Üye’nin Depar.io’dan talepte bulunma hakları bulunmamaktadır.
                </p>
                <p>
               <span> 7.3.</span>	Depar.io, Platform üzerinden sağlanan hizmetin kesintisiz olarak sunulabilmesi için gerekli tüm çabayı gösterecektir. Ancak, Platform’un öngörülemeyen bir nedenle geçici olarak servis dışı kaldığı hallerde Depar.io’nun Üye’ye karşı bir sorumluğu doğmayacaktır.
                </p>
                </li>
                <li>
                <span> Hizmet Bedeli ve Ödeme Koşulları</span>
                <p>
               <span>8.1.</span> Üye, Sözleşme’ye konu Platform’u kullanması karşılığında ilk etapta Depar.io’ya herhangi bir bedel ödemeyecektir.
                </p>
                <p>
              <span>8.2.</span> 	Depar.io, Sözleşme’nin yürürlükte olduğu herhangi bir dönemde Platform’un kullanımına yönelik Üye’den belirli bir tarih sonrası için kullanım bedeli talep edebilecektir. Bu halde Depar.io, Üye’den talep ettiği hizmet bedelini, hesaplanış yöntemini, ödeme vadesini ve diğer detayları bu Sözleşme’de belirlenmiş bildirim yöntemlerini kullanarak Üye’ye iletecektir. Üye bu türden bir bildirim üzerine en geç 15 gün içerisinde Depar.io’nun iletmiş olduğu koşulu kabul ya da reddettiğini Depar.io’ya bildirecektir. 
                </p>
                <p>
              <span> 8.3.</span> Üye’nin Platform’u Depar.io’nun talep ettiği bedeli ödemek suretiyle kullanmak istememesi halinde Üye, Sözleşme’yi bu Sözleşme’de belirlenen fesih koşullarına uyarak feshetmek hakkı saklıdır. Üye’nin Depar.io’nun bildirimine 8.2. maddesinde belirlenen sürede yanıt vermemesi, Sözleşme’yi de feshetmediği halde fesih için tanınan süre sonundan itibaren işleyecek sürede Depar.io lehine kullanım bedeli talep etme hakkı doğacaktır.
                </p>
                </li>
                <li>
                <span> Gizli Bilgilerin ve Kişisel Verilerin Korunması</span>
                <p>
             <span>9.1.</span>  Taraflar’ın Sözleşme’nin ifası sırasında veya herhangi bir surette edindiği Taraflar’a ait ticari sır, ürün listesi, fikri mülkiyet haklarına ilişkin ürünler/belgeler, Platform’a ait tüm bilgiler, Platform’da yer alan bilgiler, kişisel veriler dahil her türlü bilgi ve belge ile bu Sözleşme’nin hükümleri <strong>“Gizli Bilgi” </strong> olarak değerlendirilecektir. Taraflar, öğrendikleri Gizli Bilgi’yi 6698 numaralı Kişisel Verilerin Korunması Kanunu’nun (“KVKK”) ilgili hükümlerine uymak ve ilgili mevzuatın öngördüğü güvenlik düzeyini temin etmeye yönelik her türlü teknik ve idari tedbirleri almak suretiyle koruyacak, önceden diğer Taraf’ın yazılı iznini almadan kesinlikle ifşa etmeyecek, yayımlamayacak, kamuya açıklamayacak ya da 3. şahıslara herhangi bir şekilde aktarmayacak, işlemeyecek, 3. şahıs veya kurumlara kullandırmayacak veya onların menfaatine kullanmayacaktır.
                </p>
                <p>
                <span style={{marginBottom:'10px', display:'block'}}>9.2.	Aşağıda sayılan haller Gizli Bilgi kapsamı dışındadır.</span>
                <div style={{paddingLeft:'20px'}}>
                <p>
                     <span>9.2.1.</span>   Dayanağını açıkça belirtmek suretiyle, Taraflar’ın gizlilik yükümlülüğü olmaksızın daha önceden bildiği ve/veya yasal olarak üçüncü kişilerden gizlilik ihlali olmaksızın edindiği bilgiler (üçüncü kişilerin söz konusu bilgiyi güven yükümlülüklerini ihlal etmek suretiyle ifşa ettiği haller hiçbir şekilde bu fıkra altında değerlendirilemez), 
                        </p>
                        <p>
                       <span> 9.2.2.</span> Taraflar’ın temsilcilerinin, çalışanlarının ya da danışmanlarının kusuru söz konusu olmaksızın kamuya mal olmuş bilgiler ile kamunun zaten bildiği bilgiler,
                        </p>
                        <p>
                     <span>9.2.3.</span>  	Yürürlükte olan kanun hükümleri ya da verilmiş olan bir mahkeme kararı, resmi, idari emir gereğince açıklanması gereken bilgiler.
                        </p>
                        <p>
                       <span>9.2.4.</span> Tarafların yetkililerince açıklanmasına önceden yazılı olarak izin verilmiş bilgiler.
                        </p>
                </div>
                        
                </p>
                <p>
               <span> 9.3.</span>		Taraflar, Üye’ye ait verilerin ve Platform’a yüklenen tüm bilgi ve belgelerin Tedarikçi, Banka veya Alıcı ile paylaşabileceğinin bilincindedirler. Üye de bu veri paylaşımına muvafakat etmektedir.  
                </p>
                <p>
               <span>9.4.</span> 	Sözleşmeye konu olan hizmetlerin ifasında, Depar.io’nun, alt yüklenici kullanması halinde, Depar.io alt yüklenici ile Gizli Bilgiyi paylaşabilecek, bu paylaşım ise bu maddenin ihlali olarak değerlendirilmeyecektir. Depar.io alt yüklenicileri ile yapılacak sözleşme ve protokollerde Gizli Bilgi’nin korunması için gerekli düzenlemelere yer verecek ve alt yüklenicilerinin de bu madde hükümlerini ihlal etmemesini sağlayacaktır.
                </p>
                <p>
               <span>9.5.</span> 	Taraflar, Gizli Bilgi’yi münhasıran Sözleşme kapsamındaki yükümlülüklerinin ifası amacıyla kullanacaklardır. 
                </p>
                <p>
               <span>9.6.</span> Bununla beraber, Taraflar birbirleri ile paylaşılacakları ve 6698 sayılı Yasa ve ilgili sair yasalar kapsamında Kişisel Veri ve Özel Nitelikli Kişisel Veri (birlikte “Kişisel Veriler” olarak anılacaktır) olarak tanımlanan verileri; bu verilere dair Depar.io’nun “veri sorumlusu” sıfatını haiz olmadığı hallerle sınırlı olarak ancak ve sadece bu Sözleşme’nin ifası kapsamında kullanmakla yükümlüdür.
                </p>
                <p>
               <span>9.7.</span> Taraflar, ancak zorunlu hallerde ve işi gereği bu bilgiyi öğrenmesi gereken personele Gizli Bilgi’yi verebileceği gibi bu Gizli Bilgi’nin gizliliği ve korunması hususunda da Gizli Bilgi’nin muhafazası ve açıklanmaması için, en az kendi gizli bilgilerini koruduğu seviyede olmak üzere gerekli her türlü güvenlik önlemlerini almak, tüm personelini de bu konuda uyarmak, bilgilendirmek ve bunları sürdürmekle mükelleftirler.
                </p>
                <p>
               <span>9.8.</span> Taraflar, Sözleşme çerçevesinde edindiği Kişisel Verileri KVKK’ye uygun olarak işleyeceğini ve KVKK’nin veri güvenliğine ilişkin yükümlülüklerine uyarak, Kişisel Verilerin hukuka aykırı olarak işlenmesini ve/veya erişilmesini önlemek, Kişisel Verilerin muhafazasını sağlamak amacıyla KVKK’nin ve ilgili mevzuatın öngördüğü güvenlik düzeyini temin etmeye yönelik her türlü teknik ve idari tedbirleri alacaklardır.
                </p>
                <p>
               <span>9.9.</span> 	Taraflar, birbirlerine aktardıkları Kişisel Verilerin hukuka ve dürüstlük kurallarına uygun şekilde elde edildiğini, işlendiğini, aktarıldığını, doğru ve güncel olduğunu, bu verileri birbirlerine aktarmak için, ilgili kişileri (“veri sahibi”) KVKK’nin 10. maddesi gereğince aydınlattıklarını ve 11. maddesi gereğince hakları konusunda bilgilendirdiklerini, kanunen gereken hallerde açık rızalarını aldıklarını kabul etmektedirler.
                </p>
                <p>
              <span> 9.10.</span> 	Taraflar edindikleri Gizli Bilgi’nin Sözleşme’ye ve ilgili yasal mevzuata aykırı olarak kullanılmasının veya ifşa edilmesinin diğer Taraf’ı telafisi güç zararlara uğratacağını kabul etmektedirler. Taraflar’dan birisi, bu Sözleşme’nin ve ilgili yasal mevzuat hükümlerinin ihlalinden haberdar olmaları halinde, diğer Taraf’ı derhal ve yazılı olarak bu ihlal hakkında bilgilendirecektir. Bununla beraber; Taraflar, kişisel veri sahipleri tarafından yapılan başvurularda, ilgili başvuruyu başvurunun alınmasından itibaren en geç üç iş günü içerisinde veri sorumlusu sıfatını haiz Taraf’a iletecektir.
                </p>
                <p>
              <span>9.11.</span>  Taraflar’ın temsilcileri, çalışanları ve danışmanlarınca temin edilen Gizli Bilgi içeren tüm belgeler ve bunların herhangi bir formattaki tüm kopyaları, Gizli Bilgi’sini paylaşan Taraf’ın herhangi bir zamandaki yazılı talebi üzerine o Taraf’a derhal iade edilecek ve/veya derhal hiçbir kopya bulundurmaksızın imha edilecektir. Herhangi bir şüpheye mahal vermemek adına, iadeye konu Gizli Bilgi ayrıca her türlü bilgisayar, bilgi işlemci veya benzer diğer cihazdan silinecek veya çıkarılacaktır (var ise, yasal gereklilikler uyarınca saklanması gerekenler hariç). Gizli Bilgi’yi iade ve/veya imha etmekle yükümlü olan Taraf, Gizli Bilgi’nin imha edildiği ve her türlü bilgisayar, bilgi işlemci veya benzer diğer cihazdan silindiğine dair diğer Taraf’a derhal yazılı bir belge verecektir. 
                </p>
                </li>
              <li>
                <span> Gizlilik ve Kişisel Verilerin Korunmasına Yönelik Düzenlemeler</span>
                <p>
               <span>10.1.</span> Taraflar, Sözleşme’de düzenlenen kişisel veri işleme ve aktarımına ek olarak edinilen kişisel verilerin, bu Sözleşme’nin ifası amacıyla Depar.io veya Taraflar’dan birince görevlendirilecek üçüncü kişiler ya da kuruluşlarla 6698 sayılı Kişisel Verilerin Korunması Kanunu’na uygun olarak paylaşabileceğini kabul etmişlerdir.
                </p>
                <p>
              <span> 10.2.</span> Taraflar, kendilerine ait bilgilerin Depar.io, Depar.io’nun çalışanları veya danışmanları tarafından 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ilgili mevzuata uygun olacak şekilde işlenmesini, saklanmasını, kullanılmasını, aktarılmasına rıza göstermektedirler. Depar.io, Taraflar’dan edindiği kişisel veri ve bilgileri, (i) Platform aracılığı sunulan hizmetin geliştirilmesi, kullanılması, sunulması ve iyileştirilmesi, (ii) kullanıcı kimliklerinin doğrulanması, (iii) Sözleşme’de belirlenen koşullara uyulması, (iv) Platform’daki eksikliklerin giderilmesi ve olası sorunların çözümlenmesi, (v) yasal yükümlülüklerinin yerine getirilmesi ve muhtemel kanuna aykırı işlemlerin tespit edilmesi ile önlenmesi amaçlarıyla saklayabilir, kullanabilir ve gerekmesi halinde aktarılabilecektir.
                </p>
                <p>
              <span>10.3.</span> Depar.io, Taraflar’ın kişisel verilerinin hukuka aykırı olarak işlenmesini, bu kişisel verilere hukuka aykırı olarak erişilmesini önlemek ve bu kişisel verilerin, mevzuata uygun şekilde muhafazasını sağlamak amacıyla uygun güvenlik önlemlerini temin ederek gerekli tedbirleri alacaktır.
                </p>
                </li>
                <li>
                <span>Sözleşme’nin Süresi ve Feshi</span>
                <p>
               <span>11.1.</span> Sözleşme imza tarihinde yürürlüğe girecek olup bu tarihten itibaren bir yıl süre ile geçerli olacaktır. Bu sürenin sona erme tarihinden 3 (üç) ay önce Taraflar’dan herhangi biri Türk Ticaret Kanunu’nun 18/III maddesinde belirtildiği usullerden biri ile Sözleşme’nin feshini diğer tarafa ihbar etmedikçe, tüm hüküm ve koşulları geçerli olarak baki kalmak kaydıyla Sözleşme’nin geçerlilik süresi birer yıllık sürelerle kendiliğinden uzayacaktır.   
                </p>
                <p>
               <span>11.2.</span> Taraflar’dan birinin bu Sözleşme kapsamındaki yükümlülüklerini ihlal etmesi ve/veya Sözleşme kapsamındaki yükümlülüklerinden herhangi birinin kısmen ya da tamamen yerine getirilmemesi halinde, ihlale uğrayan Taraf, yükümlülüklerini ihlal eden Taraf’a ihlali sona erdirmesi için yazılı bildirimde bulunacaktır ve ihlalde bulunan Taraf’ça bildirimin teslim alınmasından itibaren 15 (on beş) gün içerisinde ihlali sona erdirilecektir. Bu süre sonunda ihlalin hâlâ giderilmemiş olması halinde Sözleşme’den ve kanundan doğan hakları saklı kalmak üzere ihlale uğrayan Taraf, Sözleşmeyi derhal ve tek taraflı olarak feshedebilecektir.
                </p>
                <p>
              <span>11.3.</span> 	Taraflar’dan her biri, Sözleşme süresi içinde herhangi bir zamanda, herhangi bir neden göstermeksizin ve tazmin yükümlülüğü olmaksızın en az 3 (üç) ay önceden karşı Taraf’a noter marifetiyle ihbarda bulunmak kaydı ile Sözleşme’yi tek taraflı olarak her zaman feshetme hakkına sahiptir. Bu durumda diğer Taraf Sözleşme’nin feshi sebebi ile fesheden Taraf’tan herhangi bir tazminat talebinde bulunmayacaktır. Taraflar’ın mevzuattan doğan hakları saklıdır.
                </p>
                <p>
              <span>11.4.</span> Bu Sözleşme’nin herhangi bir nedenle süresinden önce sona erdirildiği durumlarda, fesih tarihine kadar ifasına başlanmış olan işlemler Sözleşme hükümleri çerçevesinde sonuçlandırılacaktır.
                </p>
                </li>
                <li>
                <span> Mücbir Sebep Hali</span>
                <p>
              <span>12.1.</span>	Sözleşme’nin imzalandığı tarihte öngörülemeyen ve Taraflar’ın kontrolü dışında gelişen olayların ortaya çıkmasıyla, Taraflar’dan birinin ya da ikisinin bu Sözleşme’de yüklendikleri borçları kısmen ve/veya tamamen yerine getirmelerini imkansızlaştıran haller “Mücbir Sebepler” olarak kabul edilecektir.   
                </p>
                <p>
               <span>12.2.</span> 	Mücbir Sebep nedeniyle Sözleşme’de belirlenmiş olan yükümlülüklerini yerine getiremeyecek veya yerine getirmede gecikecek olan Taraf durumu, Mücbir Sebebin ortaya çıkmasından itibaren mümkün olan en kısa sürede diğer Taraf’a bildirmekle yükümlüdür. Taraflar mücbir sebep halleri nedeni ile Sözleşme’de belirtilen yükümlülüklerini tam ya da gereği gibi yerine getirememeleri halinde bundan sorumlu olmayacaklardır. Para borçları bu hükme tabi olmayıp Mücbir Sebeplerden etkilenmez.
                </p>
                <p>
              <span>12.3.</span> Mücbir Sebeplerin sona ermesinden sonra Sözleşme’nin ifasına devam edilecektir. Mücbir Sebebin 90 (doksan) günden fazla sürmesi halinde, Taraflar’dan her biri bu Sözleşme’yi herhangi bir tazmin yükümlülüğü olmaksızın ve diğer Taraf’a yazılı bildirimde bulunmak kaydı ile feshedebilecektir.
                </p>
                </li>
                <li>
                <span> Sözleşme’nin Diğer Hükümleri</span>
                <p>
               <span>13.1.</span> 	Depar.io, Platform’a ve Platform’un entegre olduğu sair elektronik mecralara ilişkin işleyiş kurallarını, Üye tarafından kendisine en son bildirilen elektronik posta adresine bildirim yapmak suretiyle her zaman değiştirebilir veya Platform’da uygun gördüğü bir bölümde ilan edebilir. Bu başlık altındaki hükümler Platform yanında uygun düştüğü ölçüde ve kıyasen Platform’un ve/veya Depar.io’nun entegre olduğu sair elektronik mecralar için de uygulanacaktır. 
                </p>
                <p>
              <span> 13.2.</span>	Üye, Depar.io’nun kontrolü dışında gelişen sebepler veya burada sayılanlar ile sınırlı olmamak kaydıyla, kanuni engellemeler telekomünikasyon ve internet-enerji altyapılarında geniş kapsamlı hizmetlerin sunulmasını engelleyecek nitelikteki genel problemler, veri aktarımı ve bağlantı sorunları Banka nezdinde kullanılmakta olan bilgisayar, ekipman ve donanımın iyi bir şekilde çalışmaması nedeniyle ya da kullanıcılarından kaynaklanan güvenlik problemleri sebebiyle geçici veya sürekli süreyle durdurulması veya hizmetlerin hiç veya gereği gibi verilememesi sebebiyle Depar.io’dan herhangi bir talepte bulunmayacaktır. Bu kapsamda Depar.io kaynaklı olmayan ve açıkça Depar.io’nun kusurunun bulunmadığı hizmet kesintilerinden ve 3. kişiler tarafından yapılacak siber saldırıların yol açtığı zararlardan Depar.io sorumlu tutulamaz. 
                </p>
                <p>
             <span>13.3.</span> Depar.io, her türlü önlem ve tedbiri almış olmasına rağmen, kendisinin ulusal veya uluslararası yasalar, yetkili makamlarca yayımlanan tebliğ, genelge ve esaslar gereğince başka türlü hareket etmesini gerektirecek haklı nedenlerin mevcut olduğu durumlarda Platform’da sunulan hizmetlerin kapsamını daraltabilir, tamamen durdurabilir. Üye, bu nedenlerle uğrayabileceği zarar ve kayıplar için hiçbir şekilde Depar.io rücu talebinde bulunmayacağını kabul eder.
                </p>
                <p>
              <span> 13.4.</span> 	Üye, Depar.io’nun yükümlülüklerini yerine getirebilmesi için Depar.io’ya bildirmekle yükümlü olduğu her türlü bilgi ve belgeleri; doğru, eksiksiz, güncel ve tam olarak bildirecektir. Üye, Platform’da yer alan tüm bu bilgileri eksiksiz, tam ve güncel olarak sisteme girmekle ve Sözleşme süresince güncel tutmakla yükümlüdür. Aksi halde, hizmetin sunulamaması, hizmet sunumunun gecikmesi/aksaması veya işbu bilgi ve belge eksikliklerinden doğrudan veya dolaylı kaynaklanan her türlü aksaklıktan doğrudan Üye sorumludur. 
                </p>
                <p>
              <span>13.5.</span> Üye, başta elektronik posta adresleri olmak üzere, Platform aracılığı ile sunulacak hizmet kapsamında Depar.io’ya ilettiği her türlü belge ve bilgilerin sürekli olarak güncel olmasından sorumludur. Üye, Depar.io’nun herhangi bir e-posta adresinden, Kayıtlı Elektronik Posta adresinden ya da Üye’nin Platform’daki hesabına Depar.io tarafından gönderilecek bilgilendirme içerikli e-postaları, duyuruları ve Depar.io’nun internet sitesi aracılığı ile yapacağı bildirimleri takip etmekle yükümlüdür. İbraz ettiği belge ve bilgilerde yanlışlık bulunması halinde veya bu belge ve bilgilerin mevzuata uygun olmadığının tespit edilmesi halinde Üye, herhangi bir tazminat ödeme yükümlülüğü altına girmeksizin Depar.io’nun hizmeti durdurma ve/veya Sözleşme’yi feshetme hakkı olduğunun bilincindedir.
                </p>
                <p>
               <span>13.6.</span> 	Üye, Depar.io’nun kendisine sunmuş olduğu hizmete yönelik olarak Platform’un yazılımlarını, donanımsal ve teknik altyapısını zarara uğratacak veya bu yapıların sürekliliğini etkileyecek her türlü davranış ve tutumdan kaçınacaktır.  Üye, bu Sözleşme’deki hizmetlerden faydalanmak için gerekli olan teknik ve donanımsal altyapı gereksinimlerini kendisi sağlamakla yükümlüdür.
                </p>
                <p>
             <span> 13.7.</span> 	Depar.io, Sözleşme uyarınca Üye’ye sağlanan Platform’a ilişkin Hizmeti uygun format ve şekilde Üye’nin hak, menfaat ve itibarına zarar vermeyecek şekilde PR, ilan ve reklam amaçlı kullanma hakkına sahiptir. Üye, ayrıca, Depar.io’yu kendisine ait isim, marka ve logoları Platform ve web sitesi üzerinde kullanması konusunda yetkilendirmiştir.
                </p>
                </li>
                <li>
                <span>Bildirim Esasları</span>
                <p>
             <span>14.1.</span>	Sözleşme'de yazılı adresler, 2004 sayılı İcra İflas Kanunu’nun 21. maddesi ve 148/a maddesi hükümleri saklı kalmak kaydıyla Taraflar’ın kanuni ikametgâhları kabul edilir ve bu adreslere yapılacak tebligatlar Taraflar’a yapılmış sayılacaktır. Taraflardan her biri Sözleşme’de yazılı adreslerinin değişmesi halinde bu adres değişikliğini 5 (beş) iş günü içerisinde iadeli taahhütlü mektup ya da noter aracılığıyla diğer Taraf’a bildirilmemesi halinde bu adreslere yönlendirilecek yazılı tebligatların ya da Tarafların KEP adreslerine yapılacak bildirimlerin geçerli bir tebligat olacağını kabul etmektedirler. 
                </p>
                <p>
              <span>14.2.</span> Türk Ticaret Kanunu’nun 18/3 maddesi uyarınca diğer Taraf’ı temerrüde düşürmeye, Sözleşme’yi feshe, Sözleşme’den dönmeye ilişkin ihbarlar veya ihtarlar haricinde, Sözleşme kapsamında diğer Taraf’a yapılacak tüm bildirimler, Taraflar’ın bu maddede belirtilen elektronik posta adreslerine yapılabilecektir.
                        <ul>
                                <li>
                                Elektronik posta adresi (Alıcı için)		: [•]
                                </li>
                                <li>
                                Elektronik posta adresi (Deper.io için)	:<a href='mailto:info@depar.io'>info@depar.io </a> 
                                </li>
                        </ul>
                </p>
                <p>
               <span>14.3.</span> 	Taraflar, Sözleşme kapsamında bu elektronik posta adreslerini kullanacaklarını kabul etmektedirler. Burada bildirimler için belirlenen e-posta adresini değiştirmek isteyen Taraf, diğer Taraf’ın Sözleşme’nin birinci maddesindeki yazışma adresine yazılı olarak yeni e-posta adresini bildirecektir. Yeni e-posta adresinin bildirilmemesi durumunda eski e-posta adresine gönderilen bildirimler geçerli sayılacaktır.
                </p>
                </li>
                <li>
                <span>Sözleşme’nin Tabi Olduğu Hukuk ve Yetki Sözleşmesi</span>
                <p>
              <span> 15.1.</span> Taraflar Sözleşme’nin Türk Hukuku’na tabii olduğunu ve Sözleşme kapsamında çıkacak tüm uyuşmazlıklarda İstanbul (Çağlayan) Mahkemeleri ve İcra Dairelerinin münhasıran yetkili olduğunu kabul etmektedirler.
                </p>
                </li>
                <li>
                <span>Sözleşmenin ve Alacakların Devri</span>
                <p>
              <span> 16.1.</span> 	Üye, Sözleşme'den doğan hak, yükümlülük ve alacaklarını, Depar.io,’nun yazılı ön izni olmadan 3. kişilere kısmen veya tamamen devir veya temlik etmeyeceğini kabul eder.
                <div style={{paddingLeft:'20px'}}>
                <p>
                     <span> 1.1.</span>  	Eğer Sözleşme’nin herhangi bir hükmü, herhangi bir sebepten ötürü geçersiz, mevzuata aykırı veya uygulanamaz hale gelirse, bu geçersizlik, uygulanamama veya mevzuata aykırılık hali olmasaydı nasıl uygulanacak idiyse, buna en yakın şekilde yorumlanır ve uygulanır. Böyle geçersiz, uygulanamaz veya mevzuata aykırı olarak kabul edilen bir hükmün mevcudiyeti diğer hükümlerin geçerliliğini, yasalara uygunluğunu ve uygulanabilirliğini etkilemez. 
                        </p>
                        <p>
                      <span> 1.2.</span> 	Ayrıca, Sözleşme’de yer alan koşullardan herhangi birinin Taraflarca farklı uygulanması, o maddenin bu şekliyle kabul edildiği anlamına gelmeyeceği gibi, söz konusu uygulama diğer maddelerin geçerliliğine de sirayet etmeyecektir. Ayrıca bir kısım maddelerin uygulanmaması, söz konusu maddelerin yürürlükten kalktığı şeklinde de yorumlanmayacaktır.
                        </p>
                        <p>
                       <span>1.3.</span> 	Sözleşme’yi değiştirecek ve/veya tamamlayacak ve/veya ek hizmetler dahil edecek her türlü anlaşmanın ve/veya tadil işleminin yazılı olarak yapılması zorunlu olup, Taraflar arasındaki sözlü anlaşmalar geçersizdir.
                        </p>
                </div>
                    
                </p>
                </li>
                <li>
                <span>Delil Sözleşmesi</span>
                <p>
               <span>17.1.</span> 	Üye, Hukuk Muhakemeleri Kanunu’nun (“HMK”) 193. maddesi çerçevesinde, Sözleşme’den doğacak uyuşmazlıklarda Depar.io’nun defter ve mikrofilm, mikrofiş, ses, teyp bantları, bilgi işlem ve bilgisayar vb. kayıtlarının HMK’nin 199. maddesi kapsamında belge olarak değerlendirilerek, geçerli delil olacağını kabul etmişlerdir.
                </p>
                </li>
                <li>
                <span> Damga Vergisi</span>
                <p>
               <span>18.1.</span> 	Sözleşme’nin tanziminden doğacak damga vergisi Taraflarca eşit olarak karşılanacaktır.
                </p>
                <p>
                Toplam on iki sayfadan meydana gelen ve iki nüsha olarak tanzim edilen bu Sözleşme [•] tarihinde imzalanmış ve aynı tarihte yürürlüğe girmiştir.  
                </p>
                </li>
            </ol>
            <div style={{ display:'flex', width:'100%', justifyContent:'space-around'}}>
                <div className='text-center'>
                <span>ALICI</span>
                
                </div>
                <div  className='text-center'>
                        <span>
                        Depar.io <br/>
                Neo Elektronik Ticaret Bilişim Teknolojileri <br/>
                Yazılım ve Danışmanlık A.Ş.
                        </span>
                </div>
            </div>
           </Row>
          
        </div>
    
)
export default MembershipAgreement;