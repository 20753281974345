export interface ISupplierItem {
    id: number;
    demandat?: string;
    inoviceTotal?: number;
    requestDate: string;
    reviewedBy?: string;
    status?: ISupplierStatus;
}

export interface ISupplierStatus {
    id: EFundStatus;
    text: string;
}

export enum EFundStatus {
    Pending = 1,
    Interested = 2,
    Expired = 3,
    Approved = 4,
    StartedInvestigation = 5,
    Rejected=6,
    Applied=7,
}