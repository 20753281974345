import React from "react";
import { Button, Divider, Row, Typography } from "antd";
import Upload from "../components/Upload";
import { DownloadOutlined } from "@ant-design/icons";
import {getDownloadSampleFileApi} from "../services/financingApi"

const { Text } = Typography;

const UploadBox = ({  fileType, setFileType, isLoadingUpload, removeFile, onUploadFile, setData, xmlFunction, setDataMustahsil }: any) => {

  const downloadSampleFile = async () => {
    try{
      const response =  await getDownloadSampleFileApi() ;
        if(response){
          const link = document.createElement('a');
          link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(response.data.sampleInvoice);
          link.setAttribute('download', 'Örnek dosya - Örnek Dosya.xlsx'); 
          link.style.display = 'none';
          document.body.appendChild(link);      
          link.click();      
          document.body.removeChild(link);
        }
      
      } catch(e){
        console.log(e)
      }
  }

  
  return (
    <Row justify="center">
      <div className="upload-box">
        <Text className="upload-title" strong>Dosya Yükleyin</Text>
        <Text strong>Excel olarak yüklediğin dosya maksimum 5 mb olabilir</Text>
        <Upload
          className="excel-yukle"
          label="Excel Dosyası Yükle"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          fileList={[]}
          loading={fileType === 'excel_dosya' && isLoadingUpload}
          onChange={(val: any) => {
            setFileType('excel_dosya');
            onUploadFile(val.file)
          }}
          onRemove={removeFile}
          isDownload={false}
          disabled={false}
        />
        <Upload
          className="excel-yukle"
          label="XML Dosyası/ları Yükle"
          accept=".xml"
          fileList={[]}
          loading={fileType === 'text/xml' && isLoadingUpload}
          onChange={(val: any) => {
            setFileType('text/xml');
            setData(
              val.fileList.map((item:any, index:any) => {
                return(
                  val.fileList[index].originFileObj
                )
                } 
                )
            )
          }}
          onClick={xmlFunction}
          onRemove={removeFile}
          isDownload={false}
          disabled={false}
          multiple="true"
        />

        {/* <Upload
          className="excel-yukle"
          label="E-müstahsil dosyası/ları Yükle"
          accept=".xml"
          fileList={[]}
          loading={fileType === 'text/xml-mustahsil' && isLoadingUpload}
          onChange={(val: any) => {
            setFileType('text/xml-mustahsil');
            setDataMustahsil(
              val.fileList.map((item: any, index: any) => {
                return (
                  val.fileList[index].originFileObj
                )
              }
              )
            )
          }}
          onClick={xmlFunction}
          onRemove={removeFile}
          isDownload={false}
          disabled={false}
          multiple="true"
        /> */}

        <Upload
          label="E-fatura Entegratörü ile Yükle"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          fileList={[]}
          loading={fileType === 'efatura_dosya' && isLoadingUpload}
          onChange={(val: any) => {
            setFileType('efatura_dosya');
            onUploadFile(val.file)
          }}
          onRemove={removeFile}
          isDownload={false}
          disabled={true}
        />
        <Divider>veya</Divider>
        <Text strong style={{marginLeft: 12}}>
          Örnek dosyayı indirip formatımıza göre bilgilerinizi doldurup daha sonra yükleme yapın.
        </Text>
        <Button
          className="download-btn"
          type="default"
          size="large"
          // loading={isLoading}
          block
          onClick={downloadSampleFile}
        >
          <DownloadOutlined style={{ fontSize: 20 }} /> Örnek Dosyamızı
          İnceleyin
        </Button>
      </div>
    </Row>
  )
}

  
export default UploadBox;

  