/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import React from 'react';
import Viewer from 'react-viewer';
import {downloadImage} from './DownloadImage';

const getCustomViewerToolbar = (defaultToolbar: any[], imageName: string) => {
  const calculateImageDegree = (image: any, degree: any) => (image.degree = (image.degree || 0) + degree);
  const resetImageDegree = (image: any) => (image.degree = 0);

  defaultToolbar.find(({key}) => key === 'rotateRight').onClick = (image: any) =>
    calculateImageDegree(image, 90);
  defaultToolbar.find(({key}) => key === 'rotateLeft').onClick = (image: any) =>
    calculateImageDegree(image, -90);
  defaultToolbar.find(({key}) => key === 'prev').onClick = resetImageDegree;
  defaultToolbar.find(({key}) => key === 'next').onClick = resetImageDegree;
  defaultToolbar.find(({key}) => key === 'reset').onClick = resetImageDegree;
  defaultToolbar.find(({key}) => key === 'download').onClick = (image: any) =>
    downloadImage(image, imageName);

  return defaultToolbar;
};

function Preview({imageName = '', images = [], visible = false, onClose}: any) {
  return (
    <>
      <Viewer
        noNavbar
        changeable={false}
        visible={visible}
        onMaskClick={onClose}
        onClose={onClose}
        activeIndex={0}
        downloadable
        scalable={false}
        customToolbar={(defaultToolbar: any) => getCustomViewerToolbar(defaultToolbar, imageName)}
        images={images}
      />
    </>
  );
}

export default Preview;
