import React from 'react';
import './App.css';
import AppRouter from './router/appRouter';
import { BrowserRouter } from 'react-router-dom';
import tr_TR from 'antd/es/locale/tr_TR';
import { ConfigProvider } from 'antd';
import Footer from "./components/Footer";


function App() {
  return (
    <>
      <ConfigProvider locale={tr_TR}>
        <BrowserRouter>
            <AppRouter />
            <Footer />
        </BrowserRouter>
      </ConfigProvider>
    </>
  );
}

export default App;
