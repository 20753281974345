export class LocalStorageService {
    static authTokenKey: string = 'depar-io-buyer-auth';
    static authName: string = 'depar-io-buyer-name';
    static authHint: string = 'depar-io-buyer-hint';
    static buyerType: string = 'depar-io-buyer-type';

    static getAuthToken() {
        return sessionStorage.getItem(this.authTokenKey) || localStorage.getItem(this.authTokenKey);
    }

    static setAuthToken(token?: string | any) {
        localStorage.setItem(this.authTokenKey, token);
        sessionStorage.setItem(this.authTokenKey, token);
    }

    static setName(name?: string | any) {
        localStorage.setItem(this.authName, name);
        sessionStorage.setItem(this.authName, name);
    }

    static getName() {
        return sessionStorage.getItem(this.authName) || localStorage.getItem(this.authName);
    }

    static setBuyerType(buyerType?: string | any) {
        localStorage.setItem(this.buyerType, buyerType);
        sessionStorage.setItem(this.buyerType, buyerType);
    }

    static getBuyerType() {
        return sessionStorage.getItem(this.buyerType) || localStorage.getItem(this.buyerType);
    }

    static removeBuyerType() {
        localStorage.removeItem(this.buyerType);
        sessionStorage.removeItem(this.buyerType);
    }

    static setIsHint(isHint?: string | any) {
        localStorage.setItem(this.authHint, isHint);
        sessionStorage.setItem(this.authHint, isHint);
    }

    static getIsHint() {
        return sessionStorage.getItem(this.authHint) || localStorage.getItem(this.authHint);
    }

    static removeAuthToken() {
        localStorage.removeItem(this.authTokenKey);
        sessionStorage.removeItem(this.authTokenKey);
    }

    static removeAuthName() {
        localStorage.removeItem(this.authName);
        sessionStorage.removeItem(this.authName);
    }

}
