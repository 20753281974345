import { EFundStatus, ISupplierStatus } from "../entities/ISupplier";

export function getStatusClassName(status?: ISupplierStatus) {
    let className = "";
    if (status) {
        if (status.id === EFundStatus.Pending)
            className = "newDemand";
        else if (status.id === EFundStatus.Interested)
            className = "interested";
        else if (status.id === EFundStatus.Approved)
            className = "approved";
        else if (status.id === EFundStatus.StartedInvestigation)
            className = "newDemand";
        else if (status.id === EFundStatus.Expired)
            className = "rejected";
        else if (status.id === EFundStatus.Rejected)
            className = "rejected";
        else if (status.id === EFundStatus.Applied)
            className = "newDemand";
    }
    return className;

}