import React, { useEffect, useState,useRef } from "react";
import { Row, Table, PaginationProps, Col, notification, Typography, DatePicker, Button, Modal, Input, Space } from "antd";
import moment from "moment";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { getInvoicesUnusetApi, updateTermInvoiceApi } from "../../services/invoiceApi";
import {  convertFloatDotSeperated } from "../../helpers/common";
import {uniq as _uniq , map as _map} from "lodash";
import type { InputRef } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import dayjs from 'dayjs';

const { Text } = Typography;

const UpdateMaturityMustahsil = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const searchInput = useRef<InputRef>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<React.Key[]>([]);
  const [invoicesUnused, setInvoicesUnused] = useState<any>();
  const [ownersList, setOwnersList] = useState<any>();
  const [ownersListTaxNumber, setOwnersListTaxNumber] = useState<any>();
  const [filteredInfo, setFilteredInfo] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [offset, setOffset] = useState(1);
  const [totalInvoices, setTotalInvoices] = useState<any>();
  const [supplierCount, setSupplierCount] = useState<any>(0);
  const [filters, setFilters] = useState<any>({
    invoiceDateBegin: '',
    invoiceDateEnd: '',
    invoiceOwnerTitle: '',
    invoiceOwnerTaxNumber: '',
    invoiceNumber:'',
    invoiceTermBegin: '',
    invoiceTermEnd: '',
  });

  const [invoiceTerm, setInvoiceTerm] = useState<string>();
  const [textInvoiceTerm, setTextInvoiceTerm] = useState<string>();
  const [data, setData] = useState<any>();

  const getAllInvoices  = async () => {  
    try {
      setLoading(true);
      const {
        invoiceDateBegin,
        invoiceDateEnd,
        invoiceOwnerTitle,
        invoiceOwnerTaxNumber,
        invoiceNumber,
        invoiceTermBegin,
        invoiceTermEnd
      } = filters;     
      const filterStr = `${invoiceDateBegin ? `invoiceDateBegin=${invoiceDateBegin}&` : ''}
        ${invoiceDateEnd ? `invoiceDateEnd=${invoiceDateEnd}&` : ''}
        ${invoiceOwnerTitle ? `invoiceOwnerTitle=${invoiceOwnerTitle}&` : ''}
        ${invoiceOwnerTaxNumber ? `invoiceOwnerTaxNumber=${invoiceOwnerTaxNumber}&` : ''}
        ${invoiceNumber ? `invoiceNumber=${invoiceNumber}&` : ''}
        ${invoiceTermBegin ? `invoiceTermBegin=${invoiceTermBegin}&` : ''}
        ${invoiceTermEnd ? `invoiceTermEnd=${invoiceTermEnd}&`: ''}`;
        const response: any = await getInvoicesUnusetApi(filterStr.trim().replaceAll(/\n/g,''), pageSize, offset);
     
      if (response) {
        setLoading(false);
        setInvoicesUnused(response.data.invoiceRecords.data);
        setTotalInvoices(response.data.invoiceRecords.totalDataCount);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error)
      if(error.response.status === 500){
        notification.error({
          message: 'Beklenmedik bir hata oluştu.',
          duration: 3,
        });
      }
    }
  }

  const checkDate = (checkDate: any, date1: any, date2:any) => {
    return moment(moment(checkDate).format('YYYY-MM-DD')).isBetween(moment(moment(date1).format('YYYY-MM-DD')), moment(moment(date2).format('YYYY-MM-DD')))
  }

  const handleDateSearch = (confirm: any) => {
    getAllInvoices()
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      invoiceDateBegin: '',
      invoiceDateEnd: '',
      invoiceOwnerTitle: '',
      invoiceOwnerTaxNumber: '',
      invoiceNumber:'',
      invoiceTermBegin: '',
      invoiceTermEnd: '',
    })
  };

  const getColumnTimeProps = (dataIndex: string) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
      <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }}>
        <Text style={{ fontWeight: 'bold', marginBottom: 10 }}>Tarih seçin veya aralık girin</Text>
        <DatePicker.RangePicker
          onChange={(e: any) => {
            setSelectedKeys(e.length ? [e] : [])
            setFilters({
              ...filters,
              invoiceDateBegin: (dataIndex === 'invoiceDate' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceDateBegin,
              invoiceDateEnd: (dataIndex === 'invoiceDate' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceDateEnd,
              invoiceTermBegin: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceTermBegin,
              invoiceTermEnd: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceTermEnd,
              invoiceNumber: filters.invoiceNumber,
              invoiceOwnerTitle: filters.invoiceOwnerTitle,
              invoiceOwnerTaxNumber:filters.invoiceOwnerTaxNumber
            })
          }}
          allowClear={false}
          style={{ width: 190 }}
          value={selectedKeys[0]}
          format="YYYY-MM-DD"
        />
        <div style={{ marginTop: 15 }}>
          <Button
            role="reset"
            style={{ width: 90 }}
            onClick={() => handleReset(clearFilters)}
            size="small"
          >
            Sıfırla
          </Button>
          <Button
            type="primary"
            role="search"
            onClick={() => {
              handleDateSearch(confirm)
            }}
            style={{ width: 90, height: 24, borderRadius: 0, marginLeft: 8 }}
            icon={<SearchOutlined />}
            size="small"
          >
            Ara
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <CalendarOutlined type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
    onFilter: (value: any, record: any) => record[dataIndex] ? checkDate(record[dataIndex],value[0], value[1]) : "",
    render: (text: string) => moment(text).format("DD-MM-YYYY")
  });

  interface DataType {
    key: string;
    invoiceNumber: string;
  }
  
  type DataIndex = keyof DataType;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
     setFilters({
      ...filters,
      invoiceNumber: selectedKeys[0],
    })
  };

  const handleResetInvoice = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      invoiceNumber: '',
    })
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder='VKN/TCKN'
          // value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleResetInvoice(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleChange = (pagination: any, _filters: any, key:any) => {
    setOffset(pagination.current);
    setPageSize(pagination.pageSize);
    setFilters({
      ...filters,
      invoiceOwnerTitle: _filters?.invoiceOwnerTitle !== null ? _filters?.invoiceOwnerTitle.map((item:any) => item) : '',
      invoiceOwnerTaxNumber: _filters?.invoiceOwnerTaxNumber !== null ? _filters?.invoiceOwnerTaxNumber.map((item:any) => item) : '',
      invoiceNumber: _filters?.invoiceNumber !== null ? _filters?.invoiceNumber.map((item:any) => item) : filters.invoiceNumber,
    })
    setFilteredInfo(_filters);
  };

  const columns: any = [
    {
      title: "E-Müstahsil No",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      fixed: 'left',
      width:180,
      ...getColumnSearchProps('invoiceNumber'),
    },
    {
      title: "Çiftçi Ünvanı",
      dataIndex: "invoiceOwnerTitle",
      key: "invoiceOwnerTitle",
      filterSearch: true,
      filters: ownersList || [],
      filteredValue: (filteredInfo && filteredInfo.invoiceOwnerTitle) || null,
      onFilter: (value: string, record: any) => record.invoiceOwnerTitle.includes(value),
      ellipsis: true,
    },
    {
      title: "Çiftçi VKN",
      dataIndex: "invoiceOwnerTaxNumber",
      key: "invoiceOwnerTaxNumber",
      filters: ownersListTaxNumber || [],
      filteredValue: (filteredInfo && filteredInfo.invoiceOwnerTaxNumber) || null,
      onFilter: (value: string, record: any) => record.invoiceOwnerTaxNumber.includes(value),
      ellipsis: true,
    },
    {
      title: "E-Müstahsil Tarihi",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      ...getColumnTimeProps('invoiceDate'),
    },
    {
      title: "E-Müstahsil Vadesi",
      dataIndex: "invoiceTerm",
      key: "invoiceTerm",
      ...getColumnTimeProps('invoiceTerm'),
    },
    {
      title: "E-Müstahsil Tutarı",
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
      render: convertFloatDotSeperated,
    },
  ];

  const showTotal: PaginationProps['showTotal'] = total => 
    <Row style={{width: '100%'}}>
        <Col lg={22} style={{textAlign: 'right', color: '#727272', fontSize: 15}}>Toplam {total}</Col>
    </Row>;

  const handleShowSize = (current: any, size: any) => {
    setOffset(current);
    setPageSize(size);
  }

  const updateTermInvoice = async () => {
    try{
     const response = await updateTermInvoiceApi({"invoiceIds":selectedRowKeys, invoiceTerm: invoiceTerm})
     if(response){
      setData(response)
      window.location.reload();
     }
   
    }catch(e:any){
      if(e.response.data.Status === 400 && e.response.data.Type.includes('InvalidInvoiceTermException')) {
        notification.warning({
          message: `E-Müstahsil ödeme vadesi iş günü olmalıdır.`,
      });
      }
    }
   
  }

  const showModal = () => {
   if(selectedRowKeys.length > 0){
    setOpen(true);
   }
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
    updateTermInvoice();
   data !== undefined && setSelectedRowKeys([]);
    setSupplierCount(0)
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const rowSelection = {
    onChange:  (selectedRowKeys:any, selectedRows:any) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRow(selectedRows);
  
      const count = selectedRows.reduce(function(prev:any, cur:any) {
        prev[cur.invoiceOwnerTaxNumber] = (prev[cur.invoiceOwnerTaxNumber] || 0) + 1;
        return prev;
      }, {});
      setSupplierCount(count)
    },
  };

  const disabledDate = (current:any) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  const dateChange = (date:any) => {
    setTextInvoiceTerm(new Date(date).toLocaleDateString('tr-TR'))
    setInvoiceTerm(new Date(date).toISOString())
  };

  useEffect(() => {
    getAllInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [offset, pageSize, filters])

  useEffect(() => {
    if(invoicesUnused && invoicesUnused.length > 0) {
      const invOwnerList = _uniq(_map(invoicesUnused, 'invoiceOwnerTitle'))
      if (invOwnerList && invOwnerList.length > 0) {
        setOwnersList(invOwnerList.reduce((newData: any, owner: any) => {
          newData.push({text: owner, value: owner})
          return newData;
        }, [])) 
      }
      const invoiceOwnerTaxNumberList = _uniq(_map(invoicesUnused, 'invoiceOwnerTaxNumber'))
      if (invoiceOwnerTaxNumberList && invoiceOwnerTaxNumberList.length > 0) {
        setOwnersListTaxNumber(invoiceOwnerTaxNumberList.reduce((newData: any, owner: any) => {
          newData.push({text: owner, value: owner})
          return newData;
        }, [])) 
      }
    }
  }, [invoicesUnused])
 

  return (
    <main>
      <Row style={{ marginTop: 50 }}>
        <Col span={24}>
          <h3 className="page-title">Çiftçi E-Müstahsil Vadesi Güncelleme</h3>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={24}>
          <p>
            Bu sayfada daha önce Çiftçilerinizin işlem yapabilmesi için yüklemiş olduğunuz ve finansman konusu olmamış e-müstahsillerin ödeme vadelerini güncelleyebilirsiniz.
          </p>
        </Col>
      </Row>
      <Row >
        <Col span={24} style={{ margin: "0px auto" }}>
          <Table
            className="w-100 invoiceTable"
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
              }}
            rowKey="id"
            pagination={{
              position: ["topRight", "none" as any],
              showLessItems: true,
              showTotal,
              showSizeChanger: true,
              pageSize,
              onShowSizeChange: handleShowSize,
              total: totalInvoices && totalInvoices,
            }}
            loading={loading}
            onChange={handleChange}
            dataSource={invoicesUnused}
            columns={columns}
            scroll={{
              x: 1200,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ margin: "0px auto" }}>
          <div className="delete-invoices-bottom" style={{justifyContent:'flex-end', alignItems:'end', flexDirection:'column'}}>
            <div style={{display:'flex', alignItems:'center', gap:'15px'}}>
              <p style={{marginBottom:'0'}}>{supplierCount && Object.keys(supplierCount).length} Çiftçide toplam {selectedRowKeys.length} e-müstahsil seçtiniz. <strong>Güncellemek istediğiniz yeni ödeme vadesi:  </strong></p>
               <DatePicker 
                onChange={dateChange} 
                disabledDate={disabledDate}
                format="DD-MM-YYYY"
                />
            </div>
            <div>
              <Button type="primary" 
                onClick={showModal} 
                disabled={selectedRowKeys.length>0 && invoiceTerm ? false : true}
                >E-Müstahsil Vadelerini Güncelle</Button>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        className="delete-invoices-modal"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
      >
        <div className="scrollbar" >
          <ul>
            {
              selectedRow.map((item:any) => {
                return(
                  <li>{item.invoiceNumber}</li>
                )
              })
            }
          </ul>
        </div>
        <p>numaralı müstahsillerin vadeleri <strong>{textInvoiceTerm}</strong> olarak güncellenecektir. İlgili e-müstahsillerin vadelerini güncellemek istediğinize 
        emin misiniz?</p>
      </Modal>
    </main>
  );
};

export default UpdateMaturityMustahsil;
