import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Typography,
  notification,
  Popconfirm,
  Input,
  Button, 
  Form
} from "antd";
import {  PlusCircleOutlined  } from "@ant-design/icons";
import { getAllSuppliers, deleteSupplierById, postSupplier } from "../../services/supplierApi";
import {
  DeleteOutlined
} from '@ant-design/icons';


const { Text } = Typography;


function SupplierList() {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<any>();
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [supplierAdd] = Form.useForm();

  const fetchAllSuppliers  = async (page:any,psize:any) => {
    try {
      setLoading(true);
      const response: any = await getAllSuppliers(page,psize); 
      if (response) {
        setLoading(false);
        setInvoices(response.data.suppliers.data);
         setTotalDataCount(response.data.suppliers.totalDataCount)
     
      }
    } catch (error: any) {
      setLoading(false);   
    }
  }

  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  };



  const deletConfirm = async (value: any) => {
    try {
      setLoading(true);
      const response: any = await deleteSupplierById(value);
      if (response) {
        setLoading(false);
        fetchAllSuppliers(page,psize);
      }
    }
    catch(e){
      console.log(e)
    }
     finally {
      setLoading(false);
    }
  };

  const columns :any = [
    {
      title: 'İsim',
      dataIndex: 'name',
      key: 'name',
      width:220,
      fixed: 'left'
    },
    {
      title: 'E-posta',
      dataIndex: 'email',
      key: 'email',
      width:220,
    },
    {
      title: 'Telefon numarası',
      dataIndex: 'gsmNumber',
      key: 'gsmNumber',
      width:220,
    },
    {
      title: 'Tedarikçi Ünvanı',
      dataIndex: 'supplierTitle',
      key: 'supplierTitle',
      width:220,
    },
    {
      title: 'Vergi Kimlik No',
      dataIndex: 'supplierTaxNumber',
      key: 'supplierTaxNumber',    
      width:220,  
    },
    {
      title: '',
      dataIndex: 'supplierId',
      key: 'supplierId',
      align :'center',
      width: 100,
      render: (value: string, record: any) => (
        <>
       {(record && !record.isRegistered) ? (
          <Popconfirm
            title="Tedarikçiyi silmek istediğinizden emin misiniz?"
            onConfirm={() => deletConfirm(value)}
            overlayClassName="delete-pop"
            okText="Evet Sil"
            cancelText="Vazgeç"
          >
            <DeleteOutlined style={{fontSize: 18, color: 'red'}}/>
          </Popconfirm>
          ) : ''}
        </>
      ),
    },
  ]
 
 
  const addSupplier = async (values:any) => {     
    try {     
      setLoading(true);
      const response: any = await postSupplier({
        supplierTaxId : values.supplierTaxNumber,
        supplierTitle : values.supplierTitle,
        email : values.email,
        gsmNumber : values.gsmNumber,
        nameSurname : values.name,      
      });     
        if (response) {
          setLoading(false);
          fetchAllSuppliers(page,psize);
          supplierAdd.resetFields();
        }
    } catch (error: any) {
      setLoading(false);
      console.log(error)
      notification.warning({
        message: "Vergi Kimlik bilgisi daha önce kullanılmış.",
        duration: 3,
      });
    }
    
   };

   useEffect(() => {
    fetchAllSuppliers(page,psize);
  }, [page,psize]);

  const columnsInput: any = [
    {
      key: 'name',
      title: 'İsim',
      dataIndex: 'name',
      width:200,
      render: () =>  <Form.Item name="name"  
        rules={[{
          required: true, 
          message: "Ad Soyad girmelisiniz."
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: 'E-posta',
      dataIndex: 'email',
      width:200,
      render: () => <Form.Item name="email"  
        rules={[{
          required: true, 
          validator: (_, value) => {
            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const patt = new RegExp(validRegex);
            return patt.test(value) 
                ? Promise.resolve()
                : Promise.reject(
                      new Error(
                          'Lütfen geçerli bir e-posta giriniz!'
                      )
                  );
        },
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: 'Telefon numarası',
      dataIndex: 'gsmNumber',
      width:200,
      render: () => <Form.Item name="gsmNumber"  
        rules={[{
          required: true, 
          validator: (_, value) => {
            const patt = new RegExp('(5)[0-9][0-9][0-9]([0-9]){6}');
            return patt.test(value) && value.length < 11
                ? Promise.resolve()
                : Promise.reject(
                      new Error(
                          'Lütfen telefon numaranızı 5xxxxxxxxx şeklinde 10 basamak olarak giriniz!'
                      )
                  );
        },
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: 'Tedarikçi Ünvanı',
      dataIndex: 'supplierTitle',
      width:200,
      render: () => <Form.Item name="supplierTitle"  
        rules={[{
          required: true, 
          message: "Tedarikçi Ünvanınızı girmelisiniz."
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: 'Vergi Kimlik No',
      dataIndex: 'supplierTaxNumber',
      width:200,
      render: () => <Form.Item name="supplierTaxNumber"  
        rules={[{
          required: true, 
          validator: (_, value) => {
          return  value?.toString().length === 10 || value?.toString().length === 11
            ? Promise.resolve()
            : Promise.reject(new Error('Lütfen geçerli bir VKN / TCKN giriniz!'));
        },
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: '',
      dataIndex: 'action',
      width: '50px',
      height :'30px',
      render: () => <Button htmlType="submit" 
      style={{border:'none', fontSize:'24px', alignItems:'center', display:'flex', height:'30px', background:'none'}}>
        <PlusCircleOutlined/></Button>
    }
  ];


  return (
    <Row>
    <Col span={24} style={{ marginBottom: 20 }}>
      <Text className="limit-title" >Tedarikçilerim</Text>
    </Col>
    <Col span={24}>
      <p>
      Platforma yüklediğiniz Tedarikçilerinizin listesini burada görebilirsiniz. Henüz kayıt olmamış olan Tedarikçilerinizi silebilirsiniz.
      </p>
    </Col>
      <Col span={24} style={{ margin: "0px auto" }}>

      <Form name="supplierAdd" form={supplierAdd} onFinish={addSupplier}>
          <Table 
            className="supplier-list"
            columns={columnsInput}
            dataSource={[{}]}
            pagination={false}
            scroll={{
              x: 900,
            }}
          />
      </Form>
        
        <Table
          className="w-100 invoiceTable"
          showHeader={false}
          pagination={{
            position: ["bottomLeft", "none" as any],
            showLessItems: true,
            showSizeChanger: true,
            current: page,
            pageSize:psize,
            total:totalDataCount, 
            onChange: handleShowSize,
          }}
          loading={loading}
          dataSource={invoices}
          columns={columns}
          rowKey="supplierId"
          scroll={{
            x: 900,
          }}
        />
      </Col>
    </Row>
  );
}

export default SupplierList;
