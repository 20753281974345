import { ServiceHelper } from ".";

const getAllSuppliers = async (page:any,psize:any): Promise<Object> => {
  return await ServiceHelper.get(`buyer/suppliers?pageNumber=${page}&pageSize=${psize}`,
    {}
  );
};

const postSupplier = async (newData: any): Promise<Object> => {
  return await ServiceHelper.post(`buyer/supplier`, newData, {} );
};

const getAllSuppliersLimit = async (searchQuery:any): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/suppliers/limit?searchQuery=${searchQuery}`,
    {}
  );
};

const deleteSupplierById = async (supId: any): Promise<Object> => {
  return await ServiceHelper.delete(`buyer/supplier/${supId}`,
    {}
  );
};

const updatedLimit = async (newData: any): Promise<Object> => {
  return await ServiceHelper.patch(`buyer/limits`, newData, {} );
};

const getRegisteredTitlesApi = async (): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/supplier/registered/titles`,
    {}
  );
};

const getFinancialTitlesApi = async (): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/financial-institutions/titles`,
    {}
  );
};

const getSuppliersHistoryApi = async (supplierId:any, financialTitleId:any,  startDate:string, finishDate: string, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/risks/suppliers/${supplierId}/history?openingDateBegin=${startDate}${finishDate ? `&openingDateEnd=${finishDate}`: ''}&financialInstitutionIds=${financialTitleId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getXlsxDonwloadApiApi = async (supplierId:any, financialTitleId:any,  startDate:string, finishDate: string, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/risks/suppliers/${supplierId}/history/xlsx?openingDateBegin=${startDate}${finishDate ? `&openingDateEnd=${finishDate}`: ''}&financialInstitutionIds=${financialTitleId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const postFarmer = async (newData: any): Promise<Object> => {
  return await ServiceHelper.post(`agriculturist`, newData, {} );
};

const getAllAgriculturistApi = async (): Promise<Object> => {
  return  await ServiceHelper.get(`agriculturist`,
    {}
  );
};


export {
  getAllSuppliers,
  getAllSuppliersLimit,
  deleteSupplierById,
  updatedLimit,
  postSupplier,
  getRegisteredTitlesApi,
  getSuppliersHistoryApi,
  getXlsxDonwloadApiApi,
  getFinancialTitlesApi,
  postFarmer,
  getAllAgriculturistApi
};