import React, { useState } from "react";
import {
  Row,
  Typography,
  Col,
  Divider,notification
} from "antd";
import readXlsxFile from "read-excel-file";
import Upload from "../../components/Upload";
import { CheckCircleOutlined, DownloadOutlined, FileOutlined,ExclamationCircleOutlined } from "@ant-design/icons";
import { uploadTedarikcisApi } from "../../services/invoiceApi";
import { Link } from 'react-router-dom';


const { Text } = Typography;

function UploadMyAgriculturist() {

  const [file, setFile] = useState<File | null>(null);
  const [successMsg, setSuccessMsg] = useState(false);
  const [activeBox, setActiveBox] = useState(false);
  const [summary, setSummary] = useState<any>([]);
  const removeFile = () => {
    setFile(null);
  };

  const downloadLink = 'https://cdn.depar.io/documents/depar.io - toplu tedarikci yukleme (ornek dosya).xlsx'

  const downloadSummary =  () => {
    if(summary){
      const link = document.createElement('a');
      link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(summary.excelReportFileBase64);
      link.setAttribute('download', 'Hata Raporu - Hata Raporu.xlsx');
        
      link.style.display = 'none';
      document.body.appendChild(link);      
      link.click();      
      document.body.removeChild(link);
    }else{
      console.log('hata oluştu')
    }    
  };


  const onUploadFile = async (f: File) => {
    const isXls = f.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 'xlsx' || 'xls' ;
    if(f.size < 1.1e6){
      if(isXls){
        try {
          setFile(f);
          const rows = await readXlsxFile(f);
          rows.shift(); // headers
          const formData = new FormData();
          formData.append("File", f);
          const response: any = await uploadTedarikcisApi(formData);
        
          if (response) {        
            setSummary(response.data)    
            setSuccessMsg(true)
           
            setTimeout(() => {  
              setSuccessMsg(false)       
              setActiveBox(true)
            },1500)           
          }
        } catch(e:any) {
          if(e.response.data.Status === 400 && e.response.data.Type.includes('InvalidFileTypeException')){
            notification.warning({
                message: `Yalnızca .xls ve .xlsx uzantılı dosya yükleyebilirsiniz!`,
            });
          }
          if(e.response.data.Status === 400 && e.response.data.Type.includes('InvalidSizeFileException')){
            notification.warning({
                message: `Dosyanızın boyutu maksimum 1MB olmalıdır!`,
            });
          }
          if(e.response.data.Status === 400 && e.response.data.Type.includes('InvalidFileFormatException') ){
            notification.warning({
                message: `Yüklemiş olduğunuz fatura excelinde eksik veriler bulunmaktadır. Lütfen kontrol edip tekrar deneyiniz.`,
            });
            setTimeout(() => {  
              window.location.reload();
            },1200)
          }
        }
      }else{
        notification.error({
          message: "Yalnızca .xls ve .xlsx uzantılı dosya yükleyebilirsiniz!",
        });
      }
    }else{
      notification.error({
        message: "Lütfen 1 MB'tan küçük bir dosya yükleyin!",
      });
    }
    
    
   
  };

  const onRemoveFile = (e:any) =>{
    e.isTrusted = true
    removeFile();
    setActiveBox(false)
  }

    
  return (
    <>
      {successMsg ? (
        <Row>
          <Col span={20} style={{display: 'grid', justifyContent: 'center', gap: 15, padding: '20px 0px'}}>
            <CheckCircleOutlined style={{fontSize: 48, color: '#5ad363'}} className="tick-icon" />
            <Text>Çiftçi listeniz başarılı bir şekilde yüklendi.</Text>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="upload-supplier">
          <Col span={8}>
            <Text className="limit-title">Çiftçi Yükle</Text>
          </Col>
          <Col
            span={14}
            offset={1}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <a
              className="dosyaBtn ant-btn"
              style={{ marginRight: 15, height: 48, lineHeight:'46px' }}
              href={downloadLink}
              download
            >
              <DownloadOutlined className="download-icon" />
               Örnek Dosya İndir
            </a>
            <Upload
              style={{with:'200px', display:'flex'}}
              label="Toplu Çiftçi Yükle"
              className="tedarikci-btn"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              fileList={file ? [file] : []}
              onChange={(val: any) => onUploadFile(val.file)}
              onRemove={(e:any) => onRemoveFile(e)}
              isDownload={false}
              disabled={false}
             
            />
          </Col>
        </Row>
        {
          successMsg && (
            <Row>
              <Col span={20} style={{display: 'grid', justifyContent: 'center', gap: 15, padding: '20px 0px'}}>
                <CheckCircleOutlined style={{fontSize: 48, color: '#5ad363'}} className="tick-icon" />
                <Text>Çiftçi listeniz başarılı bir şekilde yüklendi.</Text>
              </Col>
            </Row>
          )           
        }
       
        {
          !activeBox ? (
            <Row style={{marginTop:'30px'}}>
            <Col>
              <p>Örnek dosyamızı indirerek, Çiftçilerinizin listesini toplu olarak yükleyebilirsiniz. Ayrıca tek tek Çiftçi tanımlayabilirsiniz.</p>
            </Col>
          </Row>

          ) : (
            <Row style={{marginBottom:'50px',marginTop:'15px'}}>
              <Col  span={24} style={{ margin: "0px auto" }} >
                <Divider style={{marginBottom:'20px'}}/>
                <div className="sum-information">
                  <div className="box box1">
                      <div className="icon-box">
                        <FileOutlined />
                      </div>
                      <span className="txt3">Toplam</span>
                      <span className="txt5">{summary.totalLoadedSupplierCount} Adet</span>
                    </div>
                    <div className="box box3">
                      <div className="icon-box">
                        <CheckCircleOutlined />
                      </div>
                      <span className="txt3">Başarılı</span>
                      <span className="txt5">{summary.totalValidSupplierCount} Adet</span>
                    </div>
                    <div className="box box4">
                      <div className="icon-box">
                        <ExclamationCircleOutlined />
                      </div>
                      <span className="txt3">Hatalı</span>
                      <span className="txt5">{summary.totalInvalidSupplierCount} Adet</span>
                      <Link to=''  className="link" onClick={downloadSummary}>Hata Raporu</Link>
                    </div>    
                </div>
              </Col>
            </Row>
          )
        }
        </>
      )}
      {/* {!successMsg && tableData && tableData.length > 0 && (
        <>
          <Button
            style={{ height: "46.5px" }}
            className="tab-send-btn"
            type="default"
            htmlType="submit"
            size="large"
            onClick={uploadFile}
            loading={isLoadingUpload}
            block
          >
            Gönder
          </Button>
          <Row className="limit-table-box">
            <Table
              className="supplier-table"
              pagination={false}
              columns={columns}
              style={{ width: "100%" }}
              dataSource={tableData}
              rowClassName={(record) => record.isDuplicate ? 'dublicate-row' : '' }
            />
          </Row>
        </>
      )} */}
    </>
  );
}

export default UploadMyAgriculturist;

