/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { LocalStorageService } from "../helpers/LocalStorage";

interface UserState {
  email: string;
  navShow: Boolean;
  isLoggedIn: string;
}

const initialState = {
  email: '',
  navShow: false,
  isLoggedIn:
  LocalStorageService.getAuthToken() || LocalStorageService.getAuthToken() !== 'undefined' || LocalStorageService.getAuthToken() !== null
      ? LocalStorageService.getAuthToken()
      : '',
} as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.email = action.payload;
    },
    setNavShow(state, action) {
      state.navShow = action.payload;
    },
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
  },
  },
});

export const { setUserData, setNavShow, setLoggedIn} = userSlice.actions;
export default userSlice.reducer;
